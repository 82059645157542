import FrameComponent1 from '../../components/mobile/FrameComponent1';

export const Onboarding3 = () => {
  return (
    <div className='h-full pb-24 pt-[90px] px-[0.625rem] gap-[0.8125rem] tracking-[normal] font-montserrat text-updated-color-new-black flex flex-col justify-center items-center'>
      <img
        className='w-full h-[40%] object-cover'
        loading='lazy'
        alt=''
        src='/uni.png'
      />
      <FrameComponent1 />
    </div>
  );
};
