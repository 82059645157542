import { useState, useRef, useEffect } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { EditFileFolder } from './EditFileFolder';
import axios from 'axios';
import { jwtTokenRef, serverURL } from '../../httpContext';
import PortalPopup from './PortalPopup';
import MoveFile from './MoveFile';

const formatDate = (dateString) => {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Get individual components of the date
  const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month
  const year = date.getFullYear();

  // Construct the formatted date string
  const formattedDate = `${month}-${day}-${year}`;

  return formattedDate;
};

export const MobileDoc = ({
  deleteFileHandler,
  handleFileOpen,
  file,
  handleFileRemove,
  refreshFiles,
}) => {
  const deleteWidth = 0;
  const [newFileName, setNewFileName] = useState(file?.name);
  const [isRenamingFile, setIsRenamingFile] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openMoveFilePopup, setOpenMoveFilePopup] = useState(false);
  const popupRef = useRef(null);

  const inputRef = useRef(null);
  const docRef = useRef(null);

  const handleFocus = () => {
    inputRef.current.select();
  };

  const handleFileRename = async (transcriptId, newName) => {
    await axios.post(
      `${serverURL}/rename_transcript?transcript_id=${transcriptId}&new_name=${newName}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
          'Content-Type': 'application/json',
        },
        responseType: 'json', // Ensure the response is a blob
      },
    );
  };

  const handleFileMove = async (transcriptId, folderId) => {
    console.log('running');
    await axios.post(
      `${serverURL}/add_file_to_folder?folder_id=${folderId}&file_id=${transcriptId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
          'Content-Type': 'application/json',
        },
        responseType: 'json', // Ensure the response is a blob
      },
    );

    setOpenMoveFilePopup(false);
    refreshFiles();
  };

  const handleKeyDownFileRename = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setIsRenamingFile(false);
      handleFileRename(file.id, newFileName);
    }
  };

  useEffect(() => {
    // Disable context menu on long press to avoid interference
    const disableContextMenu = (e) => e.preventDefault();
    document.addEventListener('contextmenu', disableContextMenu);

    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
    };
  }, []);

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      className='relative w-full h-full text-left text-[1rem] font-montserrat'
      onClick={() =>
        handleFileOpen(file, file?.raw_transcript || file?.content)
      }
      ref={docRef}
    >
      {openMoveFilePopup && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
          onOutsideClick={() => {
            setOpenMoveFilePopup(false);
          }}
        >
          <MoveFile
            close={() => {
              setOpenMoveFilePopup(false);
            }}
            handleFileMove={(folderId) => {
              handleFileMove(file.id, folderId);
            }}
          />
        </PortalPopup>
      )}
      {openPopup && (
        <div ref={popupRef} className='absolute w-fit h-fit z-50 right-[0px]'>
          <EditFileFolder
            isFile={true}
            setIsRenamingFile={setIsRenamingFile}
            setOpenPopup={setOpenPopup}
            setOpenMoveFilePopup={setOpenMoveFilePopup}
            handleFileRemove={handleFileRemove}
            file={file}
          />
        </div>
      )}
      <div
        className='absolute top-0 bottom-0 right-0 bg-red-500 overflow-hidden rounded-xl'
        style={{ width: deleteWidth > 0 ? deleteWidth + 50 : deleteWidth }}
        onClick={(e) => {
          e.stopPropagation();
          deleteFileHandler(file);
        }}
      >
        <img
          src='delete-icon.svg'
          alt='Delete'
          className='w-[1.688rem] h-[1.875rem] absolute top-6 right-[1.5rem]'
        />
      </div>
      <div
        className='flex py-4 h-fit bg-white transition-transform transform relative z-10 rounded-xl'
        style={{ transform: `translateX(${-deleteWidth}px)` }}
      >
        <MoreOutlined
          className='absolute top-1 right-0 text-2xl p-2 pr-0'
          onClick={(e) => {
            e.stopPropagation();
            setOpenPopup(true);
          }}
        />
        <div className='ml-3 flex flex-col gap-3'>
          {!isRenamingFile ? (
            <div className='font-medium line-clamp-2 flex gap-1 flex-wrap pr-10'>
              <span>{newFileName}</span>
              <span>{formatDate(file?.created_at)}</span>
            </div>
          ) : (
            <div className='w-full flex items-center gap-2'>
              <input
                className='font-medium'
                value={newFileName}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => setNewFileName(e.target.value)}
                enterKeyHint='done'
                ref={inputRef}
                type='text'
                onFocus={handleFocus}
                onKeyDown={handleKeyDownFileRename}
              />
              <button
                className='font-medium p-2'
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRenamingFile(false);
                  handleFileRename(file.id, newFileName);
                }}
              >
                Done
              </button>
            </div>
          )}
          <div className='text-[0.75rem] text-darkslategray-100 line-clamp-2'>
            {file?.content}
          </div>
        </div>
      </div>
    </div>
  );
};
