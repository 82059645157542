import { useNavigate } from 'react-router-dom';

import { useEffect } from 'react';

export const OutOfCreditsWindow = ({ onClose, onBuyCredits }) => {
  const navigate = useNavigate();

  // Close the window when clicking outside of the rectangle
  useEffect(() => {
    const handleClickOutside = (event) => {
      const target = event.target;
      const modal = document.getElementById('out-of-credits-modal');
      if (modal && !modal.contains(target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className='fixed inset-0 z-50 bg-overlay-new-background flex items-center justify-center text-center text-base text-updated-color-new-black font-montserrat'>
      <div className='flex justify-center h-fit p-10 w-1/3 min-w-[250px]'>
        <div
          id='out-of-credits-modal'
          className='rounded-lg cursor-pointer bg-gray-200 shadow-[0_3px_5.5px_rgba(140,_140,_140,_0.25)] w-full overflow-hidden flex flex-col items-center justify-center py-[15px] px-5 box-border'
        >
          {' '}
          {/* Added padding inside */}
          <div className='flex justify-end w-full'>
            <button
              onClick={onClose}
              className='text-gray-500 hover:text-gray-700 text-2xl'
            >
              &times; {/* Close icon */}
            </button>
          </div>
          <p className='font-medium'>Oops!</p>
          <p className='font-medium w-full'>
            It looks like you're out of credits. No worries! Top up now to keep
            using our awesome services!
          </p>
          <div className='w-11/12 h-[.5px] bg-black my-2'></div>{' '}
          {/* Optional Separator */}
          <div style={{ color: '#007ef3' }}>
            <button
              className='rounded-[5px] py-2 px-4 font-medium'
              style={{ color: '#007ef3' }}
              onClick={() => {
                onBuyCredits();
              }}
            >
              Add Credits
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
