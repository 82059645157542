import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverURL, jwtTokenRef } from '../httpContext';
import axios from 'axios';

const SelectRoomToJoin = ({ onOutsideClickFunc }) => {
  const [roomId, setRoomId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const roomIdInputRef = useRef(null);
  const navigate = useNavigate();

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        roomIdInputRef.current &&
        !roomIdInputRef.current.contains(event.target)
      ) {
        onOutsideClickFunc();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onOutsideClickFunc]);

  const handleJoinClick = async () => {
    if (roomId.trim()) {
      const queryParam = password
        ? `?password=${encodeURIComponent(password)}`
        : '';

      try {
        const response = await axios.get(
          `${serverURL}/room/${roomId}${password ? `?password=${password}` : ''}`,
          {
            headers: { Authorization: `Bearer undefined` },
          },
        );

        if (response.status === 200) {
          const roomData = response.data;
          navigate(`/broadcast/${roomId}${queryParam}`);
        } else {
          setError(
            response.detail || 'An error occurred while joining the room',
          );
        }
      } catch (error) {
        setError(
          error.response?.data?.detail ||
            'An error occurred while joining the room',
        );
      }
    } else {
      alert('Please enter a valid Room ID');
    }
  };

  return (
    <div className='w-full h-full flex justify-center items-center relative overflow-hidden cursor-pointer max-w-full max-h-full text-left text-lg text-updated-color-new-black font-montserrat'>
      <div
        ref={roomIdInputRef}
        className='flex flex-col items-center justify-center rounded-xl bg-gray-100 p-8 relative'
      >
        <div className='font-semibold mb-4 text-center'>
          Enter Room ID to Join
        </div>
        <div className='flex flex-col w-full'>
          <input
            type='text'
            className='rounded-md w-full p-2 border border-gray-300 mb-2'
            value={roomId}
            onChange={(e) => setRoomId(e.target.value)}
            placeholder='Room ID'
          />
          <input
            type='text'
            value={password}
            className='rounded-md w-full p-2 border border-gray-300'
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Optional Password'
          />
        </div>
        <button
          className='mt-4 cursor-pointer [border:none] min-w-[250px] py-2.5 px-5 rounded-3xs flex flex-row items-center justify-center [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6),_rgba(121,_119,_209,_0.62))]'
          onClick={handleJoinClick}
        >
          Join Room
        </button>
        {error && (
          <div className='mt-4 text-red-500 text-sm text-center'>{error}</div>
        )}
        <button
          className='absolute top-4 right-4 w-8 h-8 cursor-pointer'
          alt='Close'
          onClick={onOutsideClickFunc}
        >
          <img
            src='/interface--close-circle-1.svg'
            alt='Close'
            className='w-full h-full'
          />
        </button>
      </div>
    </div>
  );
};

export default SelectRoomToJoin;
