export const Switch = ({ isOn, handleToggle, onColor, text }) => {
  return (
    <label className='flex items-center cursor-pointer'>
      <div className='relative'>
        <input
          type='checkbox'
          className='sr-only'
          checked={isOn}
          onChange={handleToggle}
        />
        <div
          className={`block w-11 pr-0.5 h-5 rounded-full border-solid border-1 border-white ${
            isOn ? onColor : 'bg-gray-600'
          } transition-colors duration-300 ease-in-out`}
        ></div>
        <div
          className={`absolute text-2xs flex items-center justify-center left-0.5 top-[1.5px] bg-white w-fit p-0.5 h-4 font-medium rounded-full transition-transform duration-300 ease-in-out ${
            isOn
              ? 'text-dodgerblue-200 transform translate-x-full'
              : 'text-gray-600'
          }`}
        >
          {isOn ? 'On' : 'Off'}
        </div>
      </div>
      <div className='ml-1 text-gray-700 font-medium'>{text}</div>
    </label>
  );
};
