import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtTokenRef, serverURL, userInfoRef } from '../../httpContext';
import axios from 'axios';

const EmailVerification = () => {
  const navigate = useNavigate();

  const onGroupIconClick = useCallback(() => {
    navigate('/signup');
  }, [navigate]);

  useEffect(() => {
    const checkEmailVerification = () => {
      if (userInfoRef.current?.verified_email) {
        navigate('/transcribe');
      }
    };

    const interval = setInterval(checkEmailVerification, 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  const handleResendEmail = async () => {
    try {
      const response = await axios.post(
        `${serverURL}/request-email-verification`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtTokenRef.current}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.status === 200) {
        console.log('Email verification sent');
      } else if (response.status === 403) {
        console.log('Email verification failed: Forbidden');
      } else {
        console.log('Email verification failed');
      }
    } catch (error) {
      console.error('Error during email verification:', error);
    }
  };

  return (
    <div className='max-w-[1512px] w-full mx-auto relative h-screen bg-white font-montserrat'>
      {/* Header */}
      <header className='py-8 flex justify-between items-center'>
        <div className='flex items-center'>
          <div className='flex items-center'>
            <img
              className='h-10 w-10'
              alt='Interpret Logo'
              src='/1024px-x-1024px-round-w-text1.svg'
            />
            <span className='ml-2 text-updated-color-blue text-2xl md:text-3xl tracking-[-0.02em] font-medium'>
              interpret
            </span>
          </div>
        </div>
      </header>

      <main className='px-6 md:px-12 relative py-8'>
        {/* Back Button */}
        <button
          onClick={onGroupIconClick}
          className='mb-8 p-0 text-gray-600 hover:text-gray-800 absolute top-14 left-0'
        >
          <img className='w-10 h-10' alt='Back' src='/group-1707478204.svg' />
        </button>

        {/* Main Content */}
        <div className='max-w-2xl mx-auto'>
          <h1 className='text-4xl md:text-[36px] font-semibold text-not-black-black text-center mb-16'>
            Create an Account
          </h1>

          <div className='space-y-4 text-center'>
            <p className='text-xl font-medium text-updated-color-new-black'>
              Verification code has been sent to {userInfoRef.current?.email}
            </p>

            <p className='text-lg text-unselected-text'>
              Didn't receive email?{' '}
              <button
                onClick={handleResendEmail}
                className='text-dodgerblue-200 underline hover:text-blue-600 transition-colors text-lg'
              >
                Resend
              </button>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EmailVerification;
