import { useState, useEffect, useRef } from 'react';
import RoomSelectLanguageOverlay from '../../components/mobile/RoomSelectLanguageOverlay';
import { useLocation } from 'react-router-dom';
import { socket } from '../../socketContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { serverURL, jwtTokenRef, translateTexts } from '../../httpContext';
import { CaretDownOutlined } from '@ant-design/icons';
import { Switch } from '../../components/Switch';
import RoomLinkAndQRCode from '../../components/mobile/RoomLinkAndQRCode';

const GuestView = () => {
  const navigate = useNavigate();

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesMerged, setMessagesMerged] = useState([]);
  const messagesRef = useRef(messages);
  const messagesContainerRef = useRef(null);
  const shouldScroll = useRef(false);
  const [language, setLanguage] = useState('');
  const languageRef = useRef(language);
  const playingAudioRef = useRef(false);
  const liveAudioEnabledRef = useRef(false);
  const [liveAudioEnabled, setLiveAudioEnabled] = useState(false);
  var blankSrc =
    'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
  const audioContextRef = useRef(new Audio(blankSrc));
  audioContextRef.current.autoplay = true;
  const audioQueueRef = useRef([]);
  const startProcessingIndexRef = useRef(messagesRef.current.length);
  const linkAndQRCodeRef = useRef(null);
  const [showLinkQRCode, setShowLinkQRCode] = useState(false);
  const roomIdRef = useRef(null);
  const passwordRef = useRef(null);

  const toggleLiveAudio = () => {
    if (!liveAudioEnabled) {
      // // Enable live audio
      // audioContextRef.current = new (window.AudioContext ||
      // 	window.webkitAudioContext)();

      // // Resume the audio context if it's suspended
      // if (audioContextRef.current.state === 'suspended') {
      // 	audioContextRef.current.resume().then(() => {
      // 		console.log('AudioContext resumed');
      // 	});
      // }
      audioContextRef.current.play();
      // new Audio(blankSrc)
      // audioContextRef.current.autoplay = true;

      setLiveAudioEnabled(true);

      // Set the starting index to the current messages length
      var lastFinalMessage = 0;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i]['is_final']) {
          lastFinalMessage = i + 1;
          break;
        }
      }
      startProcessingIndexRef.current = lastFinalMessage;
    } else {
      // Disable live audio
      setLiveAudioEnabled(false);
      // Clear the audio queue and stop playback
      audioQueueRef.current = [];
      playingAudioRef.current = false;
      audioContextRef.current.pause();
    }
  };

  useEffect(() => {
    liveAudioEnabledRef.current = liveAudioEnabled;
  }, [liveAudioEnabled]);

  useEffect(() => {
    languageRef.current = language;
  }, [language]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const roomId = location.pathname.split('/').pop();
    const password = params.get('password');
    console.log('Room ID:', roomId);
    console.log('Password:', password);
    if (roomId !== null) {
      roomIdRef.current = roomId;
    }
    if (password !== null) {
      passwordRef.current = password;
    }
  }, []);

  const location = useLocation();

  const playNextAudio = async () => {
    if (playingAudioRef.current || audioQueueRef.current.length === 0) {
      return;
    }

    if (audioContextRef.current && !audioContextRef.current.ended) {
      return;
    }

    if (!liveAudioEnabledRef.current) {
      // Clear the queue if live audio is disabled
      audioQueueRef.current = [];
      return;
    }

    // Ensure audioContext is created and resumed
    // if (!audioContextRef.current) {
    // audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    // }

    // Resume the audio context if it's suspended
    // if (audioContextRef.current.state === 'suspended') {
    // 	await audioContextRef.current.resume();
    // }

    playingAudioRef.current = true;

    if (!audioContextRef.current) {
      console.error('AudioContext is not initialized.');
      playingAudioRef.current = false;
      return;
    }

    const audioBlob = audioQueueRef.current.shift();

    const audioUrl = URL.createObjectURL(audioBlob);
    var audioRefToUse = audioContextRef.current;
    audioRefToUse.src = audioUrl;
    audioRefToUse.currentTime = 0;
    audioRefToUse.play();
    audioRefToUse.onended = () => {
      audioRefToUse.pause();
      playingAudioRef.current = false;
      URL.revokeObjectURL(audioUrl);
      playNextAudio(); // Play the next audio in the queue
    };
  };

  var tasks = [];

  useEffect(() => {
    console.log('Live audio enabled:', liveAudioEnabled);
    console.log('Messages length:', messages.length);
    console.log('Start processing index:', startProcessingIndexRef.current);

    if (!liveAudioEnabledRef.current) {
      return;
    }

    if (tasks.length > 0) {
      return;
    }

    var textToAdd = '';
    for (let i = startProcessingIndexRef.current; i < messages.length; i++) {
      const message = messages[i];
      console.log('Processing message:', message);
      if (message['is_final']) {
        let text = message['transcript'];
        if (
          languageRef.current !== 'Original' &&
          message['corrected_transcripts'][languageRef.current] !== undefined
        ) {
          text = message['corrected_transcripts'][languageRef.current];
        }
        textToAdd = textToAdd + ' ' + text;
        console.log('Text to add:', textToAdd);
        // var blob = computeBlob(text)
        // tasks.push(blob)
      }
    }
    if (textToAdd != '') {
      var blob = computeBlob(textToAdd);
      tasks.push(blob);
    }

    var lastFinalMessage = 0;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i]['is_final']) {
        lastFinalMessage = i + 1;
        break;
      }
    }
    startProcessingIndexRef.current = lastFinalMessage;

    // wait for all the blobs to be computed
    Promise.all(tasks).then((blobs) => {
      // const mergedBlob = new Blob(blobs); // Merge the blobs into one
      for (let i = 0; i < blobs.length; i++) {
        audioQueueRef.current.push(blobs[i]);
      }
      tasks = [];
      playNextAudio();
    });
  }, [messages, liveAudioEnabled]);

  async function computeBlob(text) {
    try {
      console.log('Computing blob for text:', text);
      const response = await axios.post(
        `${serverURL}/text_to_speech?room_id=${roomIdRef.current}`,
        { text: text },
        {
          responseType: 'blob',
        },
      );

      if (response.status === 200) {
        console.log('Computed blob successfully');
        const audioBlob = response.data;
        return audioBlob;
      } else {
        throw new Error(`Received status code ${response.status}`);
      }
    } catch (error) {
      console.error('Error in computeBlob:', error);
    }
  }

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  const updateMessagesMerged = (messagesUpdated) => {
    let messagesMergedUpdated = [];
    for (let i = 0; i < messagesUpdated.length; i++) {
      if (messagesUpdated[i]['transcript'] != '') {
        const currentTimestamp = messagesUpdated[i]['timestamp'];
        const lastMergedMessage =
          messagesMergedUpdated[messagesMergedUpdated.length - 1];

        if (
          i > 0 &&
          !messagesUpdated[i]['did_speaker_change'] &&
          !messagesUpdated[i]['is_ai_answer'] &&
          !messagesUpdated[i - 1]['is_ai_answer'] &&
          messagesUpdated[i - 1]['speaker'] === messagesUpdated[i]['speaker'] &&
          messagesMergedUpdated.length > 0 &&
          lastMergedMessage['messages_merged'] < 7 &&
          currentTimestamp - lastMergedMessage['timestamp'] <= 30 // Check if the time gap is less than or equal to 7 seconds
        ) {
          lastMergedMessage['transcript'] =
            lastMergedMessage['transcript'] + messagesUpdated[i]['transcript'];

          lastMergedMessage['messages_merged']++;

          for (let key in messagesUpdated[i]['corrected_transcripts']) {
            if (lastMergedMessage['corrected_transcripts'][key] == undefined) {
              lastMergedMessage['corrected_transcripts'][key] = '';
            }
            lastMergedMessage['corrected_transcripts'][key] =
              lastMergedMessage['corrected_transcripts'][key] +
              ' ' +
              messagesUpdated[i]['corrected_transcripts'][key];
          }
        } else {
          messagesUpdated[i]['messages_merged'] = 1;
          const timestamp = new Date(currentTimestamp * 1000);
          messagesUpdated[i]['timestamp_display'] =
            timestamp.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
          messagesMergedUpdated.push(messagesUpdated[i]);
        }
      }
    }
    console.log(messagesMergedUpdated);
    setMessagesMerged(messagesMergedUpdated);
  };

  const fetchRoomDetails = async () => {
    try {
      const response = await axios.get(
        `${serverURL}/room/${roomIdRef.current}${passwordRef.current ? `?password=${passwordRef.current}` : ''}`,
        {
          headers: { Authorization: `Bearer ${jwtTokenRef.current}` },
        },
      );
      const roomData = response.data;
      passwordRef.current = roomData.password;

      const messagesList = roomData.messages
        ? JSON.parse(roomData.messages)
        : [];

      var messagesRefCopy = JSON.parse(JSON.stringify(messagesRef.current));

      // Create a Map for faster lookup by transcript_id
      const messagesMap = new Map(
        messagesRefCopy.map((message) => [message.transcript_id, message]),
      );

      for (let i = 0; i < messagesList.length - 2; i++) {
        const matchedMessage = messagesMap.get(messagesList[i].transcript_id);
        if (matchedMessage) {
          messagesList[i].corrected_transcripts =
            matchedMessage.corrected_transcripts;
        }
      }

      setMessages(messagesList);

      var messagesListCopy = JSON.parse(JSON.stringify(messagesList));

      updateMessagesMerged(messagesListCopy || []);
      // check if we should scroll
      const messagesContainer = messagesContainerRef.current;
      if (messagesContainer) {
        const { scrollTop, clientHeight, scrollHeight } = messagesContainer;
        const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
        shouldScroll.current = atBottom;
      }
    } catch (error) {
      console.error('Error fetching room details:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchRoomDetails, 1000);

    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    let lastScrollTop = messagesContainer.scrollTop;

    const handleScroll = () => {
      const currentScrollTop = messagesContainer.scrollTop;
      if (currentScrollTop < lastScrollTop) {
        // User is scrolling up
        shouldScroll.current = false;
      }
      lastScrollTop = currentScrollTop;
    };

    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToBottom = () => {
    messagesContainerRef.current?.scrollTo({
      top: Math.ceil(messagesContainerRef.current.scrollHeight),
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (shouldScroll.current) scrollToBottom();
  }, [messagesMerged]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        linkAndQRCodeRef.current &&
        !linkAndQRCodeRef.current.contains(event.target)
      ) {
        setShowLinkQRCode(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectLanguage = (language) => {
    setIsOverlayOpen(false);
    const addLanguage = async (language) => {
      const roomId = roomIdRef.current; // Assuming roomIdRef is defined and holds the current room ID
      try {
        const response = await axios.post(
          `${serverURL}/room/${roomId}/add_language`,
          {
            language: language,
            password: passwordRef.current,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtTokenRef.current}`,
            },
          },
        );
        if (response.status === 200) {
          console.log('Language added successfully:', response.data);
        } else {
          console.error('Failed to add language:', response.status);
        }
      } catch (error) {
        console.error('Error adding language:', error);
      }
    };

    addLanguage(language);
    if (language != languageRef.current) {
      let textsToTranslate = [];
      let messagesAlreadyTranslated = [];
      console.log('messages', messagesRef.current);
      for (let i = 0; i < messagesRef.current.length; i++) {
        let message = messagesRef.current[i];
        if (message['corrected_transcripts'][language] != undefined) {
          messagesAlreadyTranslated.push(i);
          continue;
        }
        textsToTranslate.push(message.transcript);
      }
      console.log('texts to translate: ' + textsToTranslate);
      translateTexts(textsToTranslate, language).then((translations) => {
        console.log('translations: ' + translations);
        let updatedMessages = [];
        for (let i = 0; i < messagesRef.current.length; i++) {
          let message = messagesRef.current[i];
          if (messagesAlreadyTranslated.includes(i)) {
            updatedMessages.push(message);
            continue;
          } else {
            if (message['corrected_transcripts'][language] == undefined) {
              message['corrected_transcripts'][language] =
                translations[i] + ' ';
            }
            updatedMessages.push(message);
          }
        }
        setMessages(updatedMessages);
        var updatedMessagesCopy = JSON.parse(JSON.stringify(updatedMessages));
        updateMessagesMerged(updatedMessagesCopy);
        localStorage.setItem('messages', JSON.stringify(messagesMerged));
      });
    }
    setLanguage(language);
    console.log('Selected language: ', language);
  };

  return (
    <div className='w-full h-full relative'>
      {showLinkQRCode && (
        <div className='fixed inset-0 z-50 flex items-center justify-center bg-black/20'>
          <RoomLinkAndQRCode
            link={`https://interpretapp.ai/broadcast/${roomIdRef.current}${passwordRef.current ? `?password=${passwordRef.current}` : ''}`}
            onOutsideClickFunc={() => setShowLinkQRCode(false)}
            divRef={linkAndQRCodeRef}
            isWeb={false}
            shareOnly={true}
          />
        </div>
      )}
      <img
        className='w-16 h-16 absolute bottom-2 left-0 right-0 mx-auto'
        src='/guest-share.svg'
        alt=''
        onClick={() => {
          setShowLinkQRCode(true);
        }}
      />
      <div className='w-full h-full overflow-hidden flex flex-col items-start justify-start pt-14 sm:pt-[70px] px-2.5 sm:px-5 pb-20 sm:pb-[100px] box-border text-center text-xs text-updated-color-new-black font-montserrat'>
        <div className='flex flex-row items-center w-full'>
          <img
            className='h-10 w-10 cursor-pointer'
            loading='lazy'
            alt=''
            src='/arrow--arrow-left-6-circle.svg'
            onClick={() => {
              navigate('/');
            }}
          />
          {/* <div className="font-medium font-montserrat w-full mr-6">
						Room Created 03.23.2024, 13:38 PM
					</div> */}
        </div>
        <div className='flex justify-center items-center w-full mt-4 gap-2'>
          <button
            className='cursor-pointer border-none py-1 px-2 bg-white rounded-xl shadow flex items-start justify-start gap-2'
            onClick={() => {
              setIsOverlayOpen(true);
              console.log('Select Language');
            }}
          >
            <div className='flex items-center text-xs font-montserrat text-updated-color-new-black gap-1'>
              <div>{language != '' ? language : 'Select Language'}</div>
              <CaretDownOutlined />
            </div>
          </button>
          <Switch
            isOn={liveAudioEnabled}
            handleToggle={toggleLiveAudio}
            onColor='bg-dodgerblue-200'
            text='Live Audio'
          />
        </div>
        <img
          className='w-full h-px absolute bottom-20 left-0'
          loading='lazy'
          alt=''
          src='/vector-266.svg'
        />
        <div
          className='flex flex-col overflow-y-auto items-start justify-start w-full pt-0 px-0 pb-2 box-border text-xs text-updated-color-new-black font-montserrat mt-4'
          ref={messagesContainerRef}
          onTransitionEnd={() => {
            if (shouldScroll.current) {
              // check if at the bottom
              const messagesContainer = messagesContainerRef.current;
              if (messagesContainer) {
                const { scrollTop, clientHeight, scrollHeight } =
                  messagesContainer;
                const atBottom =
                  Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
                if (atBottom) {
                  shouldScroll.current = false;
                }
              }
            }
          }}
        >
          <div className='flex flex-col items-start justify-start gap-2.5'>
            {messagesMerged.map((message, index) => (
              <div
                key={index}
                className='flex flex-col items-start justify-start text-[13px]'
              >
                <div className='text-gray-500 mb-1'>
                  {message.timestamp_display}
                </div>
                {message.speaker && (
                  <strong style={{ fontSize: '18px' }}>
                    {message.speaker}:
                  </strong>
                )}{' '}
                {/* Check if speaker exists and display it */}
                {language != '' && message.corrected_transcripts[language] ? (
                  <div className='rounded-3xs [background:linear-gradient(rgba(0,_126,_243,_0.3),_rgba(0,_126,_243,_0.3)),_rgba(255,_255,_255,_0.2)] flex flex-row items-center justify-center py-4 px-[22px] box-border max-w-full mt-4'>
                    <p style={{ fontSize: '2em' }}>
                      {message.corrected_transcripts[language]}
                    </p>
                  </div>
                ) : (
                  <div className='rounded-3xs [background:linear-gradient(rgba(0,_126,_243,_0.3),_rgba(0,_126,_243,_0.3)),_rgba(255,_255,_255,_0.2)] flex flex-row items-center justify-center py-4 px-[22px] box-border max-w-full'>
                    <p style={{ fontSize: '2em' }}>{message.transcript}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {isOverlayOpen && (
        <div className='absolute top-0 left-0 w-full h-full'>
          <RoomSelectLanguageOverlay
            onClose={() => setIsOverlayOpen(false)}
            onSelect={selectLanguage}
          />
        </div>
      )}
    </div>
  );
};

export default GuestView;
