const svg = ({ color, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='text-2xl'
    >
      <path
        d='M9.50892 3.70259H2.81243L4.78373 1.73183L4.01323 0.954742L0.71582 4.25216L4.01323 7.54957L4.78373 6.77193L2.81407 4.80173H9.50892C10.3835 4.80173 11.2222 5.14913 11.8405 5.76752C12.4589 6.3859 12.8063 7.22461 12.8063 8.09914C12.8063 8.97367 12.4589 9.81238 11.8405 10.4308C11.2222 11.0491 10.3835 11.3966 9.50892 11.3966H5.11237V12.4957H9.50892C10.675 12.4957 11.7932 12.0325 12.6178 11.208C13.4423 10.3835 13.9055 9.26518 13.9055 8.09914C13.9055 6.9331 13.4423 5.81482 12.6178 4.99031C11.7932 4.16579 10.675 3.70259 9.50892 3.70259Z'
        fill={color}
      />
    </svg>
  );
};

export const UndoRedo = ({
  isUndo,
  isEnabled,
  undoHandler,
  redoHandler,
  width = 14,
  height = 13,
}) => {
  const handler = isUndo ? undoHandler : redoHandler;

  return (
    <div
      className={`h-6 w-6 flex items-center justify-center
    ${!isUndo ? 'scale-x-[-1]' : ''}
    `}
      onClick={() => {
        if (isEnabled) {
          handler();
        }
      }}
    >
      {isEnabled ? (
        <div className='' onClick={isUndo ? undoHandler : redoHandler}>
          {svg({ color: '#007EF3', width, height })}
        </div>
      ) : (
        <div className=''>{svg({ color: '#D3D3D3', width, height })}</div>
      )}
    </div>
  );
};
