import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { serverURL } from '../../httpContext';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const onGroupIconClick = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendLink = async () => {
    try {
      await axios.post(`${serverURL}/forgot-password`, { email: email });
      setEmailSent(true);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendLink();
    }
  };

  return (
    <div className='max-w-[1512px] w-full mx-auto relative bg-white font-montserrat flex'>
      <div
        className='w-fit h-fit cursor-pointer flex items-center gap-[5px] absolute top-10 left-10'
        onClick={() => {
          navigate('/');
        }}
      >
        <img
          className='h-12 w-12'
          alt=''
          src='/1024px-x-1024px-round-w-text1.svg'
        />
        <div className='tracking-[-0.02em] text-updated-color-blue text-2xl font-medium'>
          interpret
        </div>
      </div>

      <main className='flex h-screen w-full pt-52 gap-20'>
        {/* Left Section */}
        <img
          onClick={onGroupIconClick}
          className='w-10 h-10 cursor-pointer ml-20'
          alt='Back'
          src='/group-1707478204.svg'
        />
        <div className='w-full px-6 py-8 pt-0 pr-20'>
          <div className='text-4xl md:text-[36px] font-semibold text-not-black-black mb-4'>
            Forgot Password
          </div>

          {!emailSent ? (
            <div className='max-w-md'>
              <p className='text-xl text-unselected-text mb-12'>
                Enter your email address, and we'll send you a link to reset
                your password.
              </p>

              <div className='space-y-12'>
                <div className='flex items-center gap-3 border-b border-updated-color-grey1'>
                  <img
                    className='w-7 h-7'
                    alt='Email icon'
                    src='/communication--email1.svg'
                  />
                  <input
                    type='email'
                    className='w-full border-x-0 border-t-0 border-b pb-2 text-xl border-solid border-updated-color-grey1 focus:outline-none'
                    placeholder='Enter your email'
                    value={email}
                    onChange={handleEmailChange}
                    onKeyDown={handleKeyPress}
                    autoFocus
                  />
                </div>

                <button
                  onClick={handleSendLink}
                  className='w-full rounded-lg bg-updated-color-blue text-white text-2xl font-semibold py-2.5 px-4 hover:bg-blue-600 transition-colors'
                >
                  Send Link
                </button>
              </div>
            </div>
          ) : (
            <div className='max-w-md space-y-4'>
              <p className='text-xl font-medium text-updated-color-new-black'>
                We just sent an email to your inbox with a link.
              </p>
              <p className='text-lg text-unselected-text'>
                Didn't receive email?{' '}
                <button
                  onClick={handleSendLink}
                  className='text-lg text-dodgerblue-200 underline hover:text-blue-600'
                >
                  Resend
                </button>
              </p>
            </div>
          )}
        </div>
      </main>
      {/* Right Section - Gray Background */}
      <div className='w-full h-full bg-updated-color-grey1'>
        <div className='h-full flex items-center justify-center text-3xl font-semibold text-black text-center'>
          {/* Placeholder for scenario photo */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
