import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from 'react-router-dom';
import AppMobile from './AppMobile';
import { useEffect, useRef } from 'react';
import * as HttpContext from './httpContext';
import { useNavigate } from 'react-router-dom';
import { socket } from './socketContext';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';
import AppWeb from './AppWeb';

import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const jwtTokenRef = useRef();
  const userInfoRef = useRef();

  HttpContext.setJwtTokenRef(jwtTokenRef);
  HttpContext.setUserInfoRef(userInfoRef);

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: 'AIzaSyB1AKbpqYtlccr6lu0OgxLOwnPtoAKlD_I',
    authDomain: 'bluejay-408707.firebaseapp.com',
    projectId: 'bluejay-408707',
    storageBucket: 'bluejay-408707.appspot.com',
    messagingSenderId: '449248341356',
    appId: '1:449248341356:web:9970f11a72eb9ee25a1749',
    measurementId: 'G-B3KKX25JPG',
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const auth = initializeAuth(app, {
    persistence: [browserLocalPersistence, indexedDBLocalPersistence],
    popupRedirectResolver: browserPopupRedirectResolver,
  });

  var navigate = useNavigate();

  localStorage.getItem('jwtToken')
    ? (jwtTokenRef.current = localStorage.getItem('jwtToken'))
    : (jwtTokenRef.current = undefined);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (jwtTokenRef.current === undefined) {
        jwtTokenRef.current = localStorage.getItem('jwtToken');
        await HttpContext.updateAccountInfo(jwtTokenRef, userInfoRef);
        if (userInfoRef.current === undefined) {
          localStorage.removeItem('jwtToken');
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = '';
    let metaDescription = '';

    switch (pathname) {
      case '/':
        title = '';
        metaDescription = '';
        break;
      case '/filetranslate':
        title = '';
        metaDescription = '';
        break;
      case '/transcribe':
        title = '';
        metaDescription = '';
        break;
      case '/translate':
        title = '';
        metaDescription = '';
        break;
      case '/login':
        title = '';
        metaDescription = '';
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]',
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return isMobile ? <AppMobile /> : <AppWeb />;
}
export default App;
