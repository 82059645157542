const plans = [
  {
    name: 'Free',
    price: '100 Credits',
    description: [
      'As a new user, enjoy 100 free credits for your first month. Unlock full access to all features and content—completely free.',
    ],
    buttonText: 'Try for Free',
    buttonOpacity: 0.5,
  },
  {
    name: 'Lite',
    price: '$8.99/mo',
    description: [
      '1000 credits monthly (0.9 cents per credit)',
      'Up to 20 pages of document translation (50 credits/page)',
      'Up to 16 hours of real-time translation & transcription (60 credits/hour)',
      'Up to 6 hours of Zoom transcription & translation (150 credits/hour)',
    ],
    buttonText: 'Start now',
    buttonOpacity: 0.5,
  },
  {
    name: 'Standard',
    price: '$24.99/mo',
    description: [
      '3000 credits monthly (0.8 cents per credit)',
      'Up to 60 pages of document translation 50 credits/page)',
      'Up to 50 hours of real-time translation & transcription (60 credits/hour)',
      'Up to 20 hours of Zoom transcription & translation (150 credits/hour)',
    ],
    buttonText: 'Start now',
    buttonOpacity: 0.5,
  },
  {
    name: 'Enterprise',
    price: 'Let’s Talk!',
    description: ['Please contact us to discuss custom solutions and pricing.'],
    buttonText: 'Contact us',
    buttonOpacity: 0.5,
  },
];

const Plan = () => {
  return (
    <div className='max-w-[1512px] mx-auto px-10 sm:px-28 pb-44 pt-28 bg-white flex flex-col md:gap-32 gap-10 justify-center items-center text-sm text-not-black-black font-montserrat mt-[100px]'>
      <PlanHeader />
      <div className='flex lg:flex-row h-full flex-col gap-[30px] text-xl text-white'>
        {plans.map((plan, index) => {
          if (index === 2) {
            return (
              <div
                key={index}
                className='relative mt-10 md:mt-0 flex flex-col items-center justify-center gap-10'
              >
                <div className='absolute w-full -top-12 rounded-t-xl h-fit pt-5 pb-10 text-sm z-0 flex flex-col items-center justify-center bg-updated-color-blue font-semibold'>
                  MOST POPULAR
                </div>
                <PlanCard key={index} plan={plan} isPopular={index === 2} />
              </div>
            );
          } else {
            return <PlanCard key={index} plan={plan} />;
          }
        })}
      </div>
    </div>
  );
};

const PlanHeader = () => (
  <div className='flex flex-col items-center justify-center gap-[20px] text-21xl text-center'>
    <div className='font-semibold'>Plans & Pricing</div>
    <div className='max-w-[652px] relative text-sm font-medium'>
      Everything's within reach, no matter your plan. Enjoy full access to all
      features and content—the only difference is how long you can keep the fun
      going 🥳
    </div>
  </div>
);

const PlanCard = ({ plan, isPopular }) => (
  <div
    className={`rounded-xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] flex flex-col items-center py-10 px-[30px] gap-[20px] ${isPopular && 'relative z-10'}`}
    style={{ height: '345.5px' }}
  >
    <div className={`flex flex-col gap-[20px]`}>
      <div className='rounded-mini bg-updated-color-blue flex flex-row items-center justify-center py-[5px] px-[30px]'>
        <div className='relative font-medium'>{plan.name}</div>
      </div>
      <div className='flex flex-col items-start justify-start gap-[40px] text-13xl text-not-black-black'>
        <b className='relative'>{plan.price}</b>
        <div className='flex flex-col gap-2 text-xs'>
          {plan.description.map((desc, index) => (
            <div
              key={index}
              className='flex flex-row items-start justify-start gap-[10px]'
            >
              <div className='flex flex-row items-start justify-start py-[3px] px-0'>
                <div className='w-2 relative rounded-[50%] bg-updated-color-blue h-2' />
              </div>
              <div className='w-[218px] relative inline-block shrink-0'>
                {desc}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Plan;
