import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import LanguageSelectOverlay from '../../components/mobile/LanguageSelectOverlay';
import { jwtTokenRef, serverURL } from '../../httpContext';
import { useNavigate } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';

const TextTranslate = () => {
  const navigate = useNavigate();
  const [showLanguageOverlay, setShowLanguageOverlay] = useState(false);
  const [inputLanguage, setInputLanguage] = useState('English');
  const [outputLanguage, setOutputLanguage] = useState('Chinese');
  const languageOverlayOpenRef = useRef('');
  const [textInput, setTextInput] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const playAudioRef = useRef(false);
  const audioRef = useRef(null);
  const [activeTextarea, setActiveTextarea] = useState('input');
  const [languageChanged, setLanguageChanged] = useState('input');
  const [copied, setCopied] = useState(false);
  const [bottomCopied, setBottomCopied] = useState(false);
  const [isLoadingTTS, setIsLoadingTTS] = useState(false);
  const [isLoadingTTSBottom, setIsLoadingTTSBottom] = useState(false);

  const handleTextChange = (e) => {
    console.log('text change', languageOverlayOpenRef.current, activeTextarea);
    if (activeTextarea === 'input') {
      setTextInput(e.target.value);
    } else if (activeTextarea === 'output') {
      setTranslatedText(e.target.value);
    }
  };

  const playAudio = async (text) => {
    try {
      if (playAudioRef.current) {
        audioRef.current.pause();
        playAudioRef.current = false;
      }
      const response = await axios.post(
        `${serverURL}/text_to_speech?jwt_token=${jwtTokenRef.current}`,
        { text: text }, // Correctly sending the body as the second argument
        {
          responseType: 'blob', // Ensure the response is a blob
        },
      );

      if (response.status === 200) {
        setIsLoadingTTSBottom(false);
        setIsLoadingTTS(false);
        playAudioRef.current = true;
        const audioBlob = response.data;

        // Create a URL for the audio blob and handle it as needed
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();
        audioRef.current = audio;
        audio.onended = () => {
          playAudioRef.current = false;
        };
      } else {
        throw new Error(`Received status code ${response.status}`);
      }
    } catch (error) {
      setIsLoadingTTSBottom(false);
      setIsLoadingTTS(false);
      console.error('Error occurred:', error);
    }
  };

  useEffect(() => {
    if (playAudioRef.current) {
      audioRef.current.pause();
      playAudioRef.current = false;
    }

    return () => {
      if (playAudioRef.current) {
        audioRef.current.pause();
        playAudioRef.current = false;
      }
    };
  }, []);

  const translateTexts = async (texts, targetLang, setText) => {
    try {
      const response = await axios.post(
        `${serverURL}/translate_texts`,
        {
          texts: [texts],
          target_language: targetLang,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + jwtTokenRef.current,
          },
        },
      );

      if (response.status !== 200) {
        console.error('Failed to translate text');
        return;
      }

      const translations = response.data.translations;
      setText(translations[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLanguageSelect = (selectedLanguage) => {
    if (languageOverlayOpenRef.current === 'input') {
      setInputLanguage(selectedLanguage);
      setLanguageChanged('input');
    } else {
      setOutputLanguage(selectedLanguage);
      setLanguageChanged('output');
    }
    setShowLanguageOverlay(false);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      console.log('activeTextarea', activeTextarea);
      if (activeTextarea === '') {
        return;
      }
      if (activeTextarea === 'input' && textInput) {
        translateTexts(textInput, outputLanguage, setTranslatedText);
      } else if (activeTextarea === 'output' && translatedText) {
        translateTexts(translatedText, inputLanguage, setTextInput);
      }
    }, 1000);

    return () => clearTimeout(debounceTimer);
  }, [textInput, translatedText]);

  useEffect(() => {
    console.log(
      'input language changed',
      inputLanguage,
      outputLanguage,
      translatedText,
    );
    if (languageChanged === '') {
      return;
    }
    if (translatedText) {
      translateTexts(translatedText, inputLanguage, setTextInput);
    }
  }, [inputLanguage]);

  useEffect(() => {
    console.log(
      'output language changed',
      inputLanguage,
      outputLanguage,
      textInput,
    );
    if (languageChanged === '') {
      return;
    }
    if (textInput) {
      translateTexts(textInput, outputLanguage, setTranslatedText);
    }
  }, [outputLanguage]);

  return (
    <>
      <div className='flex flex-col relative [background:linear-gradient(#f4f4f4,_#f4f4f4),_#fff] h-[852px] overflow-hidden text-left text-sm text-updated-color-new-black font-montserrat items-center gap-2 px-7 pt-16'>
        {showLanguageOverlay && (
          <div className='absolute top-0 left-0 w-full h-full'>
            <LanguageSelectOverlay
              title={'Select language'}
              onClose={() => {
                setShowLanguageOverlay(false);
              }}
              onSelect={handleLanguageSelect}
            />
          </div>
        )}
        <img
          className='w-9 h-9 cursor-pointer self-start mb-2'
          alt=''
          src='/arrow-left.svg'
          onClick={() => {
            navigate('/');
          }}
        />
        <div className='rounded-3xs bg-white flex flex-col items-start justify-start pt-2.5 pb-10 px-4 relative'>
          <div
            className='flex flex-row items-center justify-start z-0 cursor-pointer'
            onClick={() => {
              languageOverlayOpenRef.current = 'input';
              setShowLanguageOverlay(true);
            }}
          >
            <div className='text-lg'>{inputLanguage}</div>
            <img
              className='w-[26px] h-[26px] object-cover'
              alt='Language Icon'
              src='/group-1707478070@2x.png'
            />
          </div>
          <textarea
            className='relative text-5xl font-medium text-deactivated-grey-color placeholder:text-black/10 z-1 w-fit h-full border-none outline-none resize-none mt-5 font-montserrat'
            placeholder='Enter text'
            value={textInput}
            rows={4}
            onChange={handleTextChange}
            onClick={() => {
              setActiveTextarea('input');
            }}
          />
          <div className='absolute bottom-[10px] right-[10px] flex flex-row items-center gap-[1px] z-2'>
            {isLoadingTTS ? (
              <div className='w-8 h-8 flex items-center justify-center'>
                <svg
                  className='animate-spin h-7 w-7 text-updated-color-blue'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-75'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className=''
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              </div>
            ) : (
              <img
                className='w-8 h-8 overflow-hidden'
                alt='Volume Down Icon'
                src='/media--volume-down.svg'
                onClick={() => {
                  if (textInput) {
                    setIsLoadingTTS(true);
                    playAudio(textInput);
                  }
                }}
              />
            )}
            {!copied ? (
              <img
                className='w-6 h-6 overflow-hidden'
                alt='Copy Icon'
                src='/files--copy.svg'
                onClick={() => {
                  navigator.clipboard.writeText(textInput);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 2000);
                }}
              />
            ) : (
              <CheckOutlined />
            )}
          </div>
        </div>
        <img
          className='w-12 relative h-12'
          alt=''
          src='/group-1707478071.svg'
          onClick={() => {
            var temp = inputLanguage;
            var temp2 = textInput;
            setActiveTextarea('');
            setLanguageChanged('');
            setInputLanguage(outputLanguage);
            setOutputLanguage(temp);
            setTextInput(translatedText);
            setTranslatedText(temp2);
          }}
        />
        <div className='rounded-3xs bg-white flex flex-col items-start justify-start pt-2.5 pb-10 px-4 relative'>
          <div
            className='flex flex-row items-center z-0 cursor-pointer text-dodgerblue-200'
            onClick={() => {
              languageOverlayOpenRef.current = 'output';
              setShowLanguageOverlay(true);
            }}
          >
            <div className='text-lg'>{outputLanguage}</div>
            <svg
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M7.58337 9.74998C7.1452 9.74998 6.75018 10.0139 6.5825 10.4187C6.41482 10.8236 6.5075 11.2895 6.81733 11.5993L12.234 17.016C12.6571 17.4391 13.343 17.4391 13.7661 17.016L19.1827 11.5993C19.4926 11.2895 19.5852 10.8236 19.4176 10.4187C19.2499 10.0139 18.8549 9.74998 18.4167 9.74998L7.58337 9.74998Z'
                fill='#007EF3'
              />
            </svg>
          </div>
          <textarea
            className='relative text-5xl text-dodgerblue-200 placeholder:text-dodgerblue-200/15 font-medium z-1 w-full h-full border-none outline-none resize-none mt-5 font-montserrat'
            placeholder='Enter text'
            value={translatedText}
            onChange={handleTextChange}
            rows={4}
            onClick={() => {
              setActiveTextarea('output');
            }}
          />
          <div className='!m-[0] absolute bottom-[10px] right-[10px] flex flex-row items-center justify-start gap-[1px] z-2'>
            {isLoadingTTSBottom ? (
              <div className='w-8 h-8 flex items-center justify-center'>
                <svg
                  className='animate-spin h-7 w-7 text-updated-color-blue'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-75'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className=''
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              </div>
            ) : (
              <img
                className='w-8 h-8 overflow-hidden shrink-0'
                alt='Volume Down Icon'
                src='/media--volume-down.svg'
                onClick={() => {
                  if (translatedText) {
                    setIsLoadingTTSBottom(true);
                    playAudio(translatedText);
                  }
                }}
              />
            )}
            {!bottomCopied ? (
              <img
                className='w-6 h-6 overflow-hidden'
                alt='Copy Icon'
                src='/files--copy.svg'
                onClick={() => {
                  navigator.clipboard.writeText(translatedText);
                  setBottomCopied(true);
                  setTimeout(() => {
                    setBottomCopied(false);
                  }, 2000);
                }}
              />
            ) : (
              <CheckOutlined />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TextTranslate;
