import { useState, useEffect } from 'react';
import { jwtTokenRef, serverURL } from '../../httpContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MobileNewFolder } from './MobileNewFolder';
import PortalPopup from './PortalPopup';
import MobileFolder from './MobileFolder';

export const FoldersContainer = ({ numFolders = 0 }) => {
  const [createFolderOpen, setCreateFolderOpen] = useState(false);
  const [folders, setFolders] = useState([]);
  const navigate = useNavigate();

  const closeCreateANewFolder = () => {
    setCreateFolderOpen(false);
  };

  const refreshFolders = async () => {
    try {
      const response = await axios.get(`${serverURL}/list_folders`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      let retrievedFolders = response.data.folders.map((folder) => ({
        name: folder.name,
        id: folder.id,
        path: folder.path,
      }));
      if (numFolders) {
        retrievedFolders = retrievedFolders.slice(0, numFolders);
      }

      setFolders(() => retrievedFolders);
    } catch (error) {
      console.error('Error fetching folders:', error);
    }
  };

  useEffect(() => {
    refreshFolders();
  }, []);

  const handleFolderNameSubmit = async (folderName) => {
    try {
      await fetch(serverURL + '/create_folder?folder_name=' + folderName, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      console.log('Folder created successfully: ' + folderName);
    } catch (error) {
      console.error('Error creating folder: ' + error);
    }
    refreshFolders();
  };

  return (
    <div className='w-full flex flex-col items-start gap-[0.937rem] text-left text-[1.5rem] text-updated-color-new-black font-montserrat h-fit'>
      {createFolderOpen && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
          onOutsideClick={closeCreateANewFolder}
        >
          <MobileNewFolder
            close={closeCreateANewFolder}
            handleFolderNameSubmit={handleFolderNameSubmit}
          />
        </PortalPopup>
      )}
      <div className='w-full flex items-center justify-between'>
        <div className='relative text-inherit font-semibold font-inherit'>
          Folders
        </div>
        <div
          className='relative text-[0.75rem] [text-decoration:underline] font-medium'
          onClick={() => navigate('/docs/folders')}
        >
          View All
        </div>
      </div>
      <div className='grid grid-cols-2 text-[1rem] justify-start text-not-black gap-8 w-full'>
        <div
          className={`w-11/12 h-20 rounded-3xs bg-slate-100 shadow-[0px_1px_2.6px_rgba(62,_62,_62,_0.2)] flex flex-col pt-[0.625rem] px-[0.625rem] pb-[2.5rem] text-not-black`}
          onClick={() => {
            setCreateFolderOpen(true);
          }}
        >
          <img
            className='w-8 h-8 relative overflow-hidden object-contain'
            alt=''
            src='/plus.svg'
          />
          <div className='relative font-medium'>Create a folder</div>
        </div>
        {folders.map((folder) => (
          <MobileFolder
            key={folder.id}
            folder={folder}
            refreshFolders={refreshFolders}
          />
        ))}
      </div>
    </div>
  );
};
