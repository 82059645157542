import { useCallback, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { top100WorldLanguages } from '../../languages';

const LanguageInputRow = ({ language, onSelect }) => (
  <div
    className='flex flex-row items-start justify-start gap-4 cursor-pointer'
    onClick={() => onSelect(language)}
  >
    <div className='flex-1 flex items-start justify-start pb-2 border-b border-gainsboro'>
      <div className='w-full font-medium font-montserrat text-base text-updated-color-new-black'>
        {language}
      </div>
    </div>
  </div>
);

const LanguageSelectOverlay = ({ title, onClose, onSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isVisible, setIsVisible] = useState(true);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // const allLanguages = ['Afrikaans', 'Akan', 'Amharic', 'Arabic', 'Armenian', 'Assamese', 'Aymara', 'Azeraijani', 'Bambara', 'Bangla', 'Basque', 'Belarusian', 'Bhojpuri', 'Bosnian', 'Bulgarian'];

  const allLanguages = top100WorldLanguages;

  const filteredLanguages = allLanguages.filter((language) =>
    language.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const languagesListRef = useRef(null);

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        languagesListRef.current &&
        !languagesListRef.current.contains(event.target)
      ) {
        setIsVisible(false);
        setTimeout(() => {
          onClose();
        }, 250);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  return (
    <div
      className='w-full h-full bg-overlay-new-background overflow-hidden flex flex-col items-center justify-end box-border leading-normal tracking-normal z-[50]'
      style={{ position: 'absolute' }}
    >
      <div
        ref={languagesListRef}
        className={`w-full max-w-md rounded-t-xl bg-white flex flex-col items-start justify-start text-left text-base font-montserrat h-3/4 transition-transform ${
          isVisible ? 'animate-slideUp' : 'animate-slideDown'
        }`}
      >
        <div className='flex items-center justify-center relative w-full pt-8'>
          <button
            onClick={() => {
              setIsVisible(false);
              setTimeout(() => {
                onClose();
              }, 250);
            }}
            className='text-dodgerblue-200 absolute left-6 top-8 text-lg'
          >
            Cancel
          </button>
          <div className='w-fit text-center'>
            <div className='font-semibold text-updated-color-new-black'>
              {title}
            </div>
          </div>
        </div>
        <div className='flex w-full sticky top-0 z-99 py-4 justify-center'>
          <div className='flex w-11/12 items-center py-2 gap-2 rounded-xl bg-gainsboro pl-2'>
            <img src='/general--search.svg' alt='' className='w-6 h-6' />
            <input
              className='border-none outline-none font-montserrat bg-transparent text-base h-6 text-updated-color-grey2 text-left p-0'
              placeholder='search'
              type='text'
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className='w-full flex-1 overflow-y-auto px-6 pb-8'>
          <div className='flex flex-col gap-6'>
            {!searchQuery && (
              <div className='flex flex-col gap-6'>
                <div className='font-semibold'>Recently Selected</div>
                <LanguageInputRow
                  language={'English'}
                  onSelect={() => {
                    onSelect('English');
                  }}
                  placeholder='English (US)'
                />
                <LanguageInputRow
                  language={'Spanish'}
                  onSelect={() => {
                    onSelect('Spanish');
                  }}
                  placeholder='Spanish'
                />
              </div>
            )}
            {!searchQuery && <div className='font-semibold'>All Languages</div>}
            {filteredLanguages.map((language, index) => (
              <LanguageInputRow
                language={language}
                placeholder={language}
                onSelect={() => {
                  onSelect(language);
                }}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelectOverlay;
