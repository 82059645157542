import { useRef } from 'react';
import axios from 'axios';
import { jwtTokenRef, userInfoRef, serverURL } from '../../httpContext';

export const CancelPlan = ({ close }) => {
  const divRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      close();
    }
  };

  const handleCancel = async () => {
    const response = await axios.post(
      `${serverURL}/unsubscribe`,
      {
        email: userInfoRef.current.email,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      },
    );

    if (response.status === 200) {
      close();
    }
  };

  return (
    <div
      className='flex items-center h-full justify-center p-4 px-10'
      onClick={handleOutsideClick}
    >
      <div
        ref={divRef}
        className='relative w-full rounded-xl bg-white flex flex-col items-center p-7 text-updated-color-new-black font-montserrat gap-4'
      >
        <img
          className='w-6 h-6 absolute top-4 right-4'
          loading='lazy'
          alt=''
          src='/interface--close-circle-1.svg'
          onClick={close}
        />
        <div className='text-lg font-bold'>
          Are you sure you want to cancel your plan?
        </div>
        <button
          onClick={close}
          className='bg-gradient-to-br py-3 from-[#007EF3]/60 to-[#7977D1]/60 rounded-3xs text-base font-medium text-white block w-full'
        >
          No
        </button>
        <button
          onClick={handleCancel}
          className='bg-gradient-to-br py-3 from-[#007EF3]/60 to-[#7977D1]/60 rounded-3xs text-base font-medium text-white block w-full'
        >
          Yes
        </button>
      </div>
    </div>
  );
};
