import { useState, useEffect } from 'react';
import { UndoOutlined } from '@ant-design/icons';

export const MobileAISummary = ({
  onSummaryContainerClick,
  summarySvg,
  backgroundColor,
  isShowingSummary,
  handleSpeakerSelect,
  isLoadingTTS,
  isPlaying,
  pauseAudio,
}) => {
  const [pauseSVG, setPauseSVG] = useState('/pause-speaker.svg');
  const [paused, setPaused] = useState(false);
  const [showRestart, setShowRestart] = useState(false);

  const handleSelect = (e) => {
    if (isLoadingTTS) return;
    if (isPlaying) {
      setPauseSVG('/pause-speaker.svg');
      setPaused(false);
    }
    handleSpeakerSelect(e);
  };

  const handlePause = (e) => {
    e.stopPropagation();

    if (!paused) {
      setPauseSVG('/play-speaker.svg');
      setPaused(true);
    } else {
      setPauseSVG('/pause-speaker.svg');
      setPaused(false);
    }

    pauseAudio();
  };

  useEffect(() => {
    if (isPlaying) {
      setShowRestart(true);
    } else {
      setShowRestart(false);
    }
  }, [isPlaying]);

  return (
    <div className='w-full h-fit flex justify-between items-center gap-[1.25rem] text-left text-[0.75rem] text-updated-color-blue font-montserrat'>
      <div className='flex flex-col gap-[1.562rem]'>
        <div className='flex flex-row items-start justify-start py-[0rem] pr-[0rem] pl-[0.375rem]'>
          <div
            className='flex flex-row items-start justify-start gap-[0.625rem] shrink-0 [debug_commit:1de1738]'
            onClick={onSummaryContainerClick}
          >
            <div
              className={`rounded-full ${backgroundColor} flex flex-row items-start justify-start py-[0.081rem] pr-[0.812rem] pl-[0.831rem]`}
            >
              <img
                className='h-[1.325rem] w-[1.094rem] relative overflow-hidden shrink-0'
                loading='lazy'
                alt=''
                src={summarySvg}
              />
            </div>
            <div className='flex flex-col items-start justify-start pt-[0.25rem] px-[0rem] pb-[0rem]'>
              <div className='relative font-medium text-transparent !bg-clip-text [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] inline-block min-w-[4.75rem]'>
                {!isShowingSummary ? 'AI Summary' : 'Show Original'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-center self-end pr-4 transition-transform transform hover:scale-105 active:scale-95'>
        {isLoadingTTS ? (
          <div className='w-8 h-8 flex items-center justify-center'>
            <svg
              className='animate-spin h-7 w-7 text-updated-color-blue'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-75'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              ></circle>
              <path
                className=''
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
          </div>
        ) : (
          <div className='w-8 h-8 flex items-center gap-2 justify-center'>
            {isPlaying && (
              <div
                onClick={handlePause}
                className={`flex items-center justify-center`}
              >
                <img className='h-6 w-6' loading='lazy' alt='' src={pauseSVG} />
              </div>
            )}
            {!showRestart ? (
              <img
                src='/speaker1.svg'
                className='h-8 w-8'
                onClick={handleSelect}
              />
            ) : (
              <UndoOutlined
                className='text-updated-color-blue text-xl'
                onClick={handleSelect}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
