import { useNavigate, useLocation } from 'react-router-dom';
import { jwtTokenRef, serverURL } from '../../httpContext';
import { useState, useEffect } from 'react';
import axios from 'axios';

const RecentsSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [recentFiles, setRecentFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        console.log('fetching files');
        await jwtTokenRef.current;
        const response = await axios.get(`${serverURL}/list_transcripts`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
          params: {
            folder_id: null, // Update as needed
            page_size: 10, // Update as needed
            page_number: 1, // Update as needed
            get_raw_transcript: false, // Update as needed
          },
        });

        const data = response.data.transcripts;
        console.log(data);
        const retrievedFiles = data.filter(
          (file) => file.transcript_type !== 'gpu' || file.transcript !== null,
        );
        setRecentFiles(retrievedFiles);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, []);

  return (
    <div className='flex flex-col rounded-xl [background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff]'>
      <div className='relative leading-[22px] font-medium p-4 text-lg'>
        Recent
      </div>
      <div className='overflow-y-auto flex flex-col items-start justify-start py-5 px-[15px] box-border gap-[10px] text-base w-full max-h-96'>
        {recentFiles.map((file, index) => (
          <div
            key={file.id}
            className='w-full rounded-3xs bg-white flex flex-col items-start justify-start py-1 pl-2.5 box-border gap-[2px]'
          >
            <div
              className='flex flex-row items-start justify-start gap-[10px] cursor-pointer'
              onClick={() => navigate(`/file_transcript?id=${file.id}`)}
            >
              <div className='relative leading-[22px] font-medium'>
                {file.name}
              </div>
              {/* <div className="relative text-sm leading-[22px] text-updated-color-grey2">
                {file.description.length > 30 ? `${file.description.slice(0, 30)}...` : file.description}
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentsSidebar;
