import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtTokenRef, serverURL, translateTexts } from '../../httpContext';
import axios from 'axios';
import { format, parseISO, set } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import RecentsSidebar from './RecentsSidebar';
import { top100WorldLanguages } from '../../languages';
import { Switch } from '../../components/Switch';
import { extractMeetingId } from '../../utils';
import { Editor } from '../../components/Editor';
import { UndoRedo } from '../../components/UndoRedo';
import { HighlightOutlined, CopyOutlined } from '@ant-design/icons';

const FileTranscript = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [fileContent, setFileContent] = useState('');
  const [file, setFile] = useState(null);
  const [summary, setSummary] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const showSummaryRef = useRef(null);
  const [outputLanguage, setOutputLanguage] = useState('Original');
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [isDownloadDropdownOpen, setDownloadDropdownOpen] = useState(false);
  const [searchBarInput, setSearchBarInput] = useState('');
  const downloadDropdownRef = useRef(null);
  const languageDropdownRef = useRef(null);
  const [shouldShowDownloadButton, setShouldShowDownloadButton] =
    useState(false);
  const [includeTimestamps, setIncludeTimestamps] = useState(true);
  const [isRemovingTimestamps, setIsRemovingTimestamps] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [saveText, setSaveText] = useState('Save');
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [copiedText, setCopiedText] = useState(false);

  const [hasZoomIntegration, setHasZoomIntegration] = useState(false);
  
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log('loading zoom oauth callback');
    const code = queryParams.get('code');
    if (code) {
      const redirectUri = window.location.origin + window.location.pathname;
      fetch(`${serverURL}/oauth-callback/zoom`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtTokenRef.current,
        },
        body: JSON.stringify({ code, redirect_uri: redirectUri }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Success:', data);
          setHasZoomIntegration(true);
          // Handle success (e.g., redirect or update state)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  const editorRef = useRef(null);

  const handleUndo = () => {
    if (editorRef.current) {
      editorRef.current.undo();
    }
  };

  const handleRedo = () => {
    if (editorRef.current) {
      editorRef.current.redo();
    }
  };

  const handleHighlight = () => {
    if (editorRef.current) {
      editorRef.current.highlight();
    }
  };

  const handleTimestampToggle = () => {
    setIncludeTimestamps(() => !includeTimestamps);

    // if it was true, we are removing timestamps
    if (includeTimestamps) {
      setIsRemovingTimestamps(true);
      axios
        .post(serverURL + '/summarize_transcript_custom_prompt', null, {
          params: {
            transcript_id: file.id,
            custom_prompt:
              'Output transcript split by paragraphs without timestamps and speaker names.',
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setIsRemovingTimestamps(false);
            return response.data;
          }
          throw new Error('Network response was not ok.');
        })
        .then((data) => {
          setFileContent(data.output);
        })
        .catch((error) => {
          console.error('Error generating summary:', error);
        });
    } else {
      setFileContent(file.transcript);
    }
  };

  const addBotToMeeting = async (meetingUrl) => {
    if (jwtTokenRef.current == undefined) {
      return;
    }

    const response = await axios.post(
      `${serverURL}/add_bot_to_zoom`,
      { meeting_url: meetingUrl },
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      },
    );
    if (response.status === 200) {
      console.log('Bot added to meeting');
      navigate(`/broadcast/${response.data}`);
    } else {
      console.error('Failed to add bot to meeting');
    }
  };

  useEffect(() => {
    // click outside the language picker popup to close it
    const handleClickOutside = (event) => {
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target)
      ) {
        setIsLanguageDropdownOpen(false);
      }
      if (
        downloadDropdownRef.current &&
        !downloadDropdownRef.current.contains(event.target)
      ) {
        setDownloadDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    let isMounted = true; // flag to check if the component is mounted

    const fetchData = async () => {
      let retrievedFile;
      var isFileTranscript = false;
      do {
        try {
          const response = await axios.get(`${serverURL}/get_transcript`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwtTokenRef.current}`,
            },
            params: {
              transcript_id: new URLSearchParams(location.search).get('id'),
            },
            onDownloadProgress: (progressEvent) => {
              // console.log('Progress event:', progressEvent);
              // const { loaded, total } = progressEvent;
              // if (total) {
              // 	const percentCompleted = Math.round((loaded * 100) / total);
              // 	console.log('Progress:', percentCompleted);
              // 	setFileContent(`Transcribing... ${percentCompleted}%`);
              // }
              if (!isFileTranscript) {
                setFileContent('Transcribing.');
                setTimeout(() => {
                  if (!retrievedFile) {
                    setFileContent('Transcribing..');
                  }
                }, 500);
                setTimeout(() => {
                  if (!retrievedFile) {
                    setFileContent('Transcribing...');
                  }
                }, 1000);
              }
            },
          });

          retrievedFile = response.data;

          console.log('response:', response);

          if (retrievedFile && isMounted) {
            // Only update state if the component is still mounted
            let rawTranscriptJson = JSON.parse(retrievedFile.raw_transcript);

            // setShowSummary(false);
            // setSummary(null);

            if (
              retrievedFile.transcript_type === 'gpu' ||
              retrievedFile.transcript_type === 'zoom'
            ) {
              console.log('setting file content', retrievedFile.transcript);
              setFileContent(retrievedFile.transcript);
              // setEditableContent(retrievedFile.transcript);
            } else if (retrievedFile.transcript_type === 'image') {
              setFileContent(rawTranscriptJson.transcription);
            } else if (retrievedFile.transcript_type === 'file') {
              var raw_transcript = retrievedFile.raw_transcript;
              if (
                raw_transcript != null &&
                JSON.parse(raw_transcript)['status'] === 'transcribing'
              ) {
                isFileTranscript = true;
                setFileContent('Translating... ');
              } else if (
                raw_transcript != null &&
                JSON.parse(raw_transcript)['status'] === 'transcribed'
              ) {
                setFileContent('Transcription is ready to download.');
              }
            }
            console.log('checking download button');

            setFile(retrievedFile);

            if (
              retrievedFile != null &&
              (retrievedFile.transcript_type == 'gpu' ||
                (retrievedFile.transcript_type === 'file' &&
                  retrievedFile.raw_transcript != null &&
                  JSON.parse(retrievedFile.raw_transcript)['status'] !==
                    'transcribing'))
            ) {
              setShouldShowDownloadButton(true);
            } else {
              setShouldShowDownloadButton(false);
            }
            // setShouldShowDownloadButton(shouldShouldDownloadButtonCheck());
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            console.log('File not found, retrying...');
            // You can also add additional logic here if needed, such as a counter for retries or a timeout
          } else {
            console.error('An error occurred:', error);
            // Handle other types of errors (e.g., network issues)
          }
        }

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      } while (
        isMounted &&
        (!retrievedFile ||
          (!retrievedFile.transcript &&
            retrievedFile.transcript_type != 'file') ||
          (retrievedFile &&
            JSON.parse(retrievedFile.raw_transcript).status === 'transcribing'))
      );
    };

    fetchData();

    return () => {
      isMounted = false; // Set the flag to false to exit the loop
    };
  }, [
    location.state?.file,
    location.search,
    new URLSearchParams(location.search).get('id'),
  ]);

  const updateTranscript = async (newTranscript) => {
    const payload = {
      transcript_id: file.id,
      transcript: newTranscript,
    };

    try {
      const response = await fetch(serverURL + '/update_transcript', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Transcript updated:', data);
        setSaveText('Saved');
        setTimeout(() => {
          setSaveText('Save');
        }, 2000);
      } else {
        console.error('Error updating transcript:', response);
      }
    } catch (error) {
      console.error('Error updating transcript:', error);
    }
  };

  const useDebouncedCallback = (callback, delay) => {
    const timeoutRef = useRef(null);

    const debouncedCallback = useCallback(
      (...args) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          callback(...args);
        }, delay);
      },
      [callback, delay],
    );

    return debouncedCallback;
  };

  const debouncedUpdateTranscript = useDebouncedCallback((newText) => {
    const formattedText = newText
      .replace(/<br>/g, '\n')
      .replace(/<\/div><div>/g, '\n')
      .replace(/<\/?div>/g, ''); // This removes any remaining <div> tags
    updateTranscript(formattedText);
  }, 1000);

  const getSummaryFromFile = (file) => {
    let raw_transcript = JSON.parse(file.raw_transcript);
    const description = raw_transcript.description;
    if (description) {
      return description;
    }
    const summaries = raw_transcript.summaries;
    if (summaries) {
      const firstSummary = Object.values(summaries)[0];
      if (firstSummary && firstSummary.summary) {
        if (firstSummary.summary.summary) {
          return firstSummary.summary.summary;
        }
        return firstSummary.summary;
      }
    }
    return '';
  };

  const handleSummarize = async () => {
    if (showSummaryRef.current) {
      setShowSummary(false);
      return;
    }
    if (getSummaryFromFile(file) !== '') {
      console.log('Summary already exists', getSummaryFromFile(file));
      setSummary(getSummaryFromFile(file));
    } else {
      setSummary('Summarizing...');
      try {
        const response = await axios.post(
          `${serverURL}/summarize_transcript?transcript_id=${file.id}&target_language=en`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwtTokenRef.current}`,
            },
          },
        );
        if (response.status !== 200) {
          throw new Error('Network response was not ok.');
        }
      } catch (error) {
        console.error('Error summarizing:', error);
        return;
      }

      const getSummary = async (retryCount = 0, maxRetries = 200) => {
        try {
          const response = await axios.get(`${serverURL}/get_raw_transcript`, {
            params: { transcript_id: file.id },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwtTokenRef.current}`,
            },
          });
          if (response.status !== 200) {
            throw new Error('Network response was not ok.');
          }

          const data = response.data;
          if (
            data.summaries &&
            data.summaries.en &&
            data.summaries.en.summary &&
            data.summaries.en.summary.summary
          ) {
            setSummary(data.summaries.en.summary.summary);
          } else if (retryCount < maxRetries) {
            setTimeout(() => getSummary(retryCount + 1, maxRetries), 2000);
          } else {
            console.error('Maximum retries reached, no summary available.');
          }
        } catch (error) {
          console.error('Error generating summary:', error);
        }
      };
      getSummary();
    }
    setShowSummary(true);
  };

  useEffect(() => {
    showSummaryRef.current = showSummary;
  }, [showSummary]);

  const translateFileContent = async (targetLanguage, text, setFunc) => {
    setIsTranslating(true);
    console.log('Translating to:', targetLanguage);
    console.log('Text:', text);

    // Split the text at \n\n to get the paragraphs
    const paragraphs = text.split('\n\n');

    // filter out empty paragraphs
    const nonEmptyParagraphs = paragraphs.filter(
      (paragraph) => paragraph.trim() !== '',
    );

    // Concatenate paragraphs with \n\n while the total length is less than 1000 characters
    const newParagraphs = [];
    let currentParagraph = '';
    for (let i = 0; i < nonEmptyParagraphs.length; i++) {
      const paragraph = nonEmptyParagraphs[i];
      if (currentParagraph.length + paragraph.length < 1000) {
        currentParagraph += paragraph + '\n\n';
      } else {
        newParagraphs.push(currentParagraph.trim());
        currentParagraph = paragraph + '\n\n';
      }
    }
    if (currentParagraph) {
      newParagraphs.push(currentParagraph.trim());
    }

    const translatedTexts = await translateTexts(newParagraphs, targetLanguage);

    setFunc(translatedTexts.join('\n\n'));
    setIsTranslating(false);
  };

  return (
    <div className='max-w-[1512px] w-full mx-auto relative bg-white text-left text-xl text-updated-color-new-black font-montserrat'>
      <div className='flex justify-center min-h-screen gap-10 py-10 px-10 h-full items-center'>
        <div className='flex flex-col gap-4 w-full self-start'>
          <img
            className='w-fit cursor-pointer'
            alt=''
            src='/group-1707478204.svg'
            onClick={() => {
              navigate('/docs');
            }}
          />
          <div className='[filter:drop-shadow(1px_1px_2.8px_rgba(140,_140,_140,_0.16)_inset)] rounded-xl border-[0.5px] border-solid border-updated-color-grey1'>
            <div className='flex justify-between items-end'>
              <div className='flex p-4 justify-between w-full'>
                <div className='flex flex-col pt-0 gap-[5px] text-base font-montserrat'>
                  <div className='relative font-semibold'>{file?.name}</div>
                  {file?.created_at && (
                    <div className='relative text-sm'>
                      Created{' '}
                      {format(parseISO(file.created_at), 'MMM dd, yyyy')}
                    </div>
                  )}
                </div>
                <div className='flex items-center gap-2'>
                  {isTranslating && (
                    <div className='w-8 h-8 flex items-center justify-center'>
                      <svg
                        className='animate-spin h-7 w-7 text-updated-color-blue'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                      >
                        <circle
                          className='opacity-75'
                          cx='12'
                          cy='12'
                          r='10'
                          stroke='currentColor'
                          strokeWidth='4'
                        ></circle>
                        <path
                          className=''
                          fill='currentColor'
                          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                        ></path>
                      </svg>
                    </div>
                  )}
                  {file != null && file.transcript_type !== 'file' && (
                    <div
                      className='relative rounded-md w-full flex items-center justify-center p-2.5 border border-gray-300 cursor-pointer'
                      onClick={() =>
                        setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
                      }
                    >
                      <div className='relative font-medium'>
                        {outputLanguage}
                      </div>
                      <img
                        className='w-[18px] relative h-[18px] overflow-hidden shrink-0 ml-2'
                        alt=''
                        src='/arrow--arrow-down-2.svg'
                      />
                      {isLanguageDropdownOpen && (
                        <div
                          ref={languageDropdownRef}
                          className='absolute top-12 right-0 w-[32rem] px-10 grid grid-cols-4 gap-6 z-10 bg-white border border-gray-300 rounded-md shadow-lg max-h-96 overflow-y-auto'
                        >
                          <div className='flex col-span-4 items-center p-2 border-solid border-x-0 border-t-0 border-b border-gray-300'>
                            <input
                              type='text'
                              placeholder='Search...'
                              className='w-full p-1 border-none outline-none text-lg'
                              value={searchBarInput}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onChange={(e) => {
                                setSearchBarInput(e.target.value);
                              }}
                            />
                            <img
                              className='w-5 h-5 ml-2'
                              alt=''
                              src='/general--search.svg'
                            />
                          </div>
                          <div
                            key={'Original'}
                            className='p-2 hover:bg-gray-200 cursor-pointer'
                            onClick={() => {
                              setOutputLanguage('Original');
                              setIsLanguageDropdownOpen(false);
                              setFileContent(file.transcript);
                            }}
                          >
                            {'Original'}
                          </div>
                          {top100WorldLanguages
                            .filter((language) =>
                              language
                                .toLowerCase()
                                .includes(searchBarInput?.toLowerCase() || ''),
                            )
                            .map((language) => (
                              <div
                                key={language}
                                className='p-2 hover:bg-gray-200 cursor-pointer w-full'
                                onClick={() => {
                                  setOutputLanguage(language);
                                  setIsLanguageDropdownOpen(false);
                                  translateFileContent(
                                    language,
                                    file.transcript,
                                    setFileContent,
                                  );
                                  console.log('summary', summary);
                                  if (summary != '') {
                                    translateFileContent(
                                      language,
                                      summary,
                                      setSummary,
                                    );
                                  }
                                }}
                              >
                                {language}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  )}
                  {shouldShowDownloadButton && (
                    <div
                      ref={downloadDropdownRef}
                      className='relative rounded-8xs bg-updated-color-blue flex flex-row items-center justify-center p-2.5 text-white cursor-pointer z-20'
                      onClick={() => {
                        setDownloadDropdownOpen(!isDownloadDropdownOpen);
                      }}
                    >
                      <div className='relative leading-[112.4%] font-medium'>
                        Download
                      </div>
                      <img
                        className='w-[18px] relative h-[18px] overflow-hidden shrink-0'
                        alt=''
                        src='/arrow--arrow-down-2.svg'
                      />
                      {isDownloadDropdownOpen && (
                        <div className='absolute z-10 text-not-black top-12 w-fit flex flex-col gap-2 rounded-8xs bg-white shadow-lg p-2'>
                          {file != null && file.transcript_type !== 'file' && (
                            <div
                              className='rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer'
                              onClick={() => {
                                const element = document.createElement('a');
                                const file = new Blob([fileContent], {
                                  type: 'text/plain',
                                });
                                element.href = URL.createObjectURL(file);
                                element.download = 'transcript.txt';
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                              }}
                            >
                              <div className='relative leading-[112.4%] font-medium'>
                                Download as TXT
                              </div>
                            </div>
                          )}
                          {file != null && file.transcript_type == 'file' && (
                            <div
                              className='rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer'
                              onClick={async () => {
                                try {
                                  const response = await axios.get(
                                    `${serverURL}/download_file?transcript_id=${file.id}`,
                                    {
                                      headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${jwtTokenRef.current}`,
                                      },
                                      responseType: 'blob', // Ensure the response is a blob (file)
                                    },
                                  );

                                  var raw_transcript = JSON.parse(
                                    file.raw_transcript,
                                  );
                                  const name =
                                    raw_transcript['original_file_name'];

                                  if (response.status === 200) {
                                    const blob = new Blob([response.data]);
                                    const element = document.createElement('a');
                                    element.href = URL.createObjectURL(blob);
                                    element.download = name; // Use a generic name for the downloaded file
                                    document.body.appendChild(element);
                                    element.click();
                                    document.body.removeChild(element);
                                  } else {
                                    console.error('Failed to download file.');
                                  }
                                } catch (error) {
                                  console.error(
                                    'Error occurred while downloading PDF file:',
                                    error,
                                  );
                                }
                              }}
                            >
                              <div className='relative leading-[112.4%] font-medium'>
                                Download Translation
                              </div>
                            </div>
                          )}
                          {file.transcript_type == 'gpu' && (
                            <div
                              className='rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer'
                              onClick={async () => {
                                try {
                                  var params = {
                                    transcript_id: file.id,
                                    language: outputLanguage,
                                  };

                                  if (outputLanguage === 'Original') {
                                    params = { transcript_id: file.id };
                                  }
                                  const response = await axios.get(
                                    `${serverURL}/get_srt_from_transcript`,
                                    {
                                      params: params,
                                      responseType: 'blob', // Ensure the response is a blob (file)
                                      headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${jwtTokenRef.current}`,
                                      },
                                    },
                                  );

                                  if (response.status === 200) {
                                    const file = new Blob([response.data], {
                                      type: 'application/x-subrip',
                                    });
                                    const element = document.createElement('a');
                                    element.href = URL.createObjectURL(file);
                                    element.download = 'transcript.srt';
                                    document.body.appendChild(element);
                                    element.click();
                                    document.body.removeChild(element);
                                  } else {
                                    console.error(
                                      'Failed to download SRT file.',
                                    );
                                  }
                                } catch (error) {
                                  console.error(
                                    'Error occurred while downloading SRT file:',
                                    error,
                                  );
                                }
                              }}
                            >
                              <div className='relative leading-[112.4%] font-medium'>
                                Download as SRT
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr className='border-solid border-t-[0.5px] border-x-0 border-b-0 border-updated-color-grey1 mx-auto w-[99%]' />
            <div className='p-10 pt-2 flex flex-col text-base font-montserrat gap-8'>
              <div
                className={`flex items-center gap-4 w-full ${showSummary ? 'justify-end' : 'justify-between'}`}
              >
                {file?.transcript_type === 'gpu' && !showSummary && (
                  <div className='flex w-fit items-center gap-4'>
                    <UndoRedo
                      isUndo={true}
                      isEnabled={canUndo}
                      undoHandler={handleUndo}
                      width={22}
                      height={22}
                    />
                    <UndoRedo
                      isUndo={false}
                      isEnabled={canRedo}
                      redoHandler={handleRedo}
                      width={22}
                      height={22}
                    />
                    <button
                      className='bg-black/60 text-white p-3 py-2 flex items-center justify-center rounded-full font-montserrat'
                      onClick={() => {
                        navigator.clipboard.writeText(fileContent);
                        setCopiedText(true);
                        setTimeout(() => setCopiedText(false), 2000);
                      }}
                    >
                      <div className='flex justify-center items-center gap-2'>
                        <CopyOutlined className='text-lg' />
                        <div className='text-white'>
                          {copiedText ? 'Copied!' : 'Copy All'}
                        </div>
                      </div>
                    </button>
                    <HighlightOutlined
                      className='text-3xl mt-2'
                      onClick={handleHighlight}
                    />
                    <button
                      onClick={() => {
                        setSaveText('Saving...');
                        debouncedUpdateTranscript(fileContent);
                      }}
                      className='font-montserrat font-bold text-base text-white bg-updated-color-blue rounded-full py-2 px-6 flex items-center gap-2 transition duration-300 ease-in-out transform hover:scale-105'
                    >
                      {saveText}
                    </button>
                  </div>
                )}
                <div className='w-fit justify-self-end flex items-center gap-2'>
                  {file?.transcript_type === 'gpu' &&
                    !showSummary &&
                    isRemovingTimestamps && (
                      <div className='w-8 h-8 flex items-center justify-center'>
                        <svg
                          className='animate-spin h-7 w-7 text-updated-color-blue'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-75'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className=''
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      </div>
                    )}
                  {file?.transcript_type === 'gpu' && !showSummary && (
                    <Switch
                      isOn={includeTimestamps}
                      handleToggle={handleTimestampToggle}
                      onColor='bg-dodgerblue-200'
                      text='Include Timestamps & Speaker'
                    />
                  )}
                  {file != null && file.transcript_type != 'file' && (
                    <div className='w-fit relative h-[23.9px] flex gap-2 items-center justify-self-end'>
                      <div
                        className={`h-full ${
                          showSummary
                            ? '[background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]'
                            : 'bg-white shadow-[0px_3px_5.5px_rgba(140,_140,_140,_0.25)]'
                        } rounded-8xl flex flex-row items-start justify-start py-[1.3px] px-[13.3px] box-border cursor-pointer`}
                        onClick={handleSummarize}
                      >
                        <img
                          className='w-[17.5px] relative h-[21.2px]'
                          alt=''
                          src={
                            showSummary ? '/ai-summary1.svg' : '/ai-summary.svg'
                          }
                        />
                      </div>
                      <div className=' font-medium text-transparent !bg-clip-text [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] font-montserrat'>
                        AI Summary
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='max-h-[40rem] min-h-[40rem] overflow-y-auto'>
                {file?.transcript_type === 'gpu' && !showSummary ? (
                  <Editor
                    ref={editorRef}
                    content={fileContent}
                    handleChange={(e) => setFileContent(e)}
                    setCanUndo={setCanUndo}
                    setCanRedo={setCanRedo}
                  />
                ) : (
                  <ReactMarkdown className='w-11/12 overflow-y-auto max-h-[90%] mx-auto'>
                    {showSummary ? summary : fileContent}
                  </ReactMarkdown>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-2/6 self-start gap-10'>
          <div className='flex items-center w-full rounded-full'>
            <svg
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='-mr-6'
            >
              <path
                d='M2 3.83337H11.3333V11.8334C11.3333 12.5698 10.7364 13.1667 10 13.1667H3.33333C2.59695 13.1667 2 12.5698 2 11.8334V3.83337Z'
                stroke='#007EF3'
                stroke-width='1.67'
                stroke-linejoin='round'
              />
              <path
                d='M11.3335 6.50004L13.1718 6.04046C13.5926 5.93527 14.0002 6.25351 14.0002 6.68722V10.3129C14.0002 10.7466 13.5926 11.0648 13.1718 10.9596L11.3335 10.5V6.50004Z'
                stroke='#007EF3'
                stroke-width='1.67'
                stroke-linejoin='round'
              />
            </svg>
            <input
              placeholder='Paste meeting URL to get caption'
              className='px-4 pl-6 py-[5px] w-full border-solid rounded-full border-[.5px] focus:outline-none  bg-transparent'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addBotToMeeting(e.target.value);
                }
              }}
            />
            <button
              className='bg-blue-600 hover:bg-blue-700 text-white rounded-full py-[0px] px-6 flex items-center gap-[0px] shadow-lg transition duration-300 ease-in-out transform hover:scale-105'
              onClick={() => {
                if (!hasZoomIntegration) {
                  window.location.href =
                    'https://zoom.us/oauth/authorize?response_type=code&client_id=Ra9qH0RyS_2WdWqQnaFWMw&redirect_uri=https://interpretapp.ai/transcribe';
                } else {
                  window.location.href =
                    'https://marketplace.zoom.us/user/dashboard'; // Link to disable integration
                }
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                x='0px'
                y='0px'
                width='60' // Increased width for better visibility
                height='60' // Increased height
                viewBox='0 0 50 50'
                fill='white'
                stroke='white'
                strokeWidth='2'
              >
                <path d='M 16.380859 4 C 9.5545055 4 4 9.5545055 4 16.380859 L 4 33.619141 C 4 40.445495 9.5545055 46 16.380859 46 L 33.619141 46 C 40.445495 46 46 40.445495 46 33.619141 L 46 16.380859 C 46 9.5545055 40.445495 4 33.619141 4 L 16.380859 4 z M 16.380859 6 L 33.619141 6 C 39.364786 6 44 10.635214 44 16.380859 L 44 33.619141 C 44 39.364786 39.364786 44 33.619141 44 L 16.380859 44 C 10.635214 44 6 39.364786 6 33.619141 L 6 16.380859 C 6 10.635214 10.635214 6 16.380859 6 z M 37.203125 16.628906 C 36.897671 16.625906 36.588652 16.704536 36.306641 16.880859 L 30.673828 20.400391 C 29.994839 18.439614 28.178052 17 25.994141 17 L 13 17 A 1.0001 1.0001 0 0 0 12 18 L 12 27.994141 C 12 30.746526 14.253474 33 17.005859 33 L 30 33 A 1.0001 1.0001 0 0 0 31 32 L 31 28.802734 L 36.306641 32.119141 C 37.434686 32.824447 39 31.957395 39 30.626953 L 39 18.373047 C 39 17.375216 38.119486 16.63778 37.203125 16.628906 z M 37 18.804688 L 37 30.195312 L 31 26.445312 L 31 22.554688 L 37 18.804688 z M 14 19 L 25.994141 19 C 27.665755 19 29 20.334245 29 22.005859 L 29 27 L 29 31 L 17.005859 31 C 15.334245 31 14 29.665755 14 27.994141 L 14 19 z'></path>
              </svg>
              <span className='text-base'>Integrate with Zoom</span>
            </button>
          </div>
          <RecentsSidebar />
        </div>
      </div>
    </div>
  );
};

export default FileTranscript;
