import { useState, useEffect, useRef } from 'react';
import { jwtTokenRef, serverURL } from '../../httpContext';
import { useNavigate } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import { EditFileFolder } from './EditFileFolder';

const MobileFolder = ({ folder, refreshFolders }) => {
  const [newFolderName, setNewFolderName] = useState('');
  const [isRenamingFolder, setIsRenamingFolder] = useState(false);
  const inputRef = useRef(null);
  const popupRef = useRef(null);
  const folderRef = useRef(null);
  const moreOutlinedRef = useRef(null);
  const navigate = useNavigate();

  const handleFolderRename = async (newName) => {
    try {
      setNewFolderName(newName);
      await fetch(
        `${serverURL}/rename_folder?folder_id=${folder.id}&new_name=${newName}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      console.log('Folder renamed successfully');
      refreshFolders();
    } catch (error) {
      console.error('Error renaming folder:', error);
    }
    setIsRenamingFolder(false);
  };

  const handleFocus = () => {
    inputRef.current.select();
    setNewFolderName(inputRef.current.value);
  };

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        if (!moreOutlinedRef.current.contains(event.target)) {
          setOpenPopup(!openPopupRef.current);
        }
        setIsRenamingFolder(false);
      }
      if (folderRef.current && !folderRef.current.contains(event.target)) {
        setIsRenamingFolder(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFolderRename(newFolderName);
    }
  };

  const [openPopup, setOpenPopup] = useState(false);

  const openPopupRef = useRef(openPopup);

  useEffect(() => {
    openPopupRef.current = openPopup;
  }, [openPopup]);

  const adjustPopupPosition = () => {
    if (popupRef.current) {
      const rect = popupRef.current.getBoundingClientRect();
      if (rect.left < 0) {
        // 16px margin
        popupRef.current.style.left = '0px';
      }
    }
  };

  useEffect(() => {
    adjustPopupPosition();
  }, [openPopup]);

  const handleFolderOpen = (folder) => {
    navigate('/docs/folder?id=' + folder.id);
  };

  const handleFolderRemove = async (folder) => {
    try {
      await fetch(`${serverURL}/delete_folder?folder_id=${folder.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      });
      console.log('Folder deleted successfully');
      refreshFolders();
    } catch (error) {
      console.error('Error deleting folder:', error);
    }
  };

  return (
    <div
      ref={folderRef}
      className={`relative h-20 rounded-3xs bg-slate-100 shadow-[0px_1px_2.6px_rgba(62,_62,_62,_0.2)] flex flex-col pt-[0.625rem] px-[0.625rem] pb-[2.5rem] drop-target w-11/12`}
      onClick={() => {
        handleFolderOpen(folder);
      }}
    >
      {openPopup && (
        <div
          ref={popupRef}
          className='absolute w-fit h-fit z-50 top-10 right-0'
        >
          <EditFileFolder
            folder={folder}
            isFile={false}
            handleFolderRemove={handleFolderRemove}
            setOpenPopup={setOpenPopup}
            setIsRenamingFolder={setIsRenamingFolder}
          />
        </div>
      )}
      <MoreOutlined
        ref={moreOutlinedRef}
        className='absolute top-0 right-0 text-2xl p-2 pr-0 z-10 text-black'
        onClick={(e) => {
          e.stopPropagation();
          setOpenPopup(!openPopupRef.current);
        }}
      />
      {isRenamingFolder ? (
        <div className='flex flex-col'>
          <input
            className='font-medium w-11/12 text-black'
            value={newFolderName || folder.name}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setNewFolderName(e.target.value)}
            enterKeyHint='done'
            type='text'
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            ref={inputRef}
          />
          <button
            className='font-medium p-2'
            onClick={(e) => {
              e.stopPropagation();
              handleFolderRename(newFolderName);
            }}
          >
            Done
          </button>
        </div>
      ) : (
        <div className='relative font-medium inline-block min-w-[5.688rem] mr-[10px] text-black'>
          {folder.name}
        </div>
      )}
    </div>
  );
};

export default MobileFolder;
