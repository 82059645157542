import { useState, useCallback, useRef, useEffect } from 'react';
import SelectTypesOfRoomToJoin from './SelectTypesOfRoomToJoin';
import SelectTypesOfRoomToStart from './SelectTypesOfRoomToStart';
import { jwtTokenRef, serverURL } from '../httpContext';
import { useNavigate } from 'react-router-dom';

const StartjoinARoomOverlay = ({ onOutsideClickFunc }) => {
  const [isSelectTypesOfRoomToJoinOpen, setSelectTypesOfRoomToJoinOpen] =
    useState(false);
  const [isSelectTypesOfRoomToStartOpen, setSelectTypesOfRoomToStartOpen] =
    useState(false);

  const [existingRooms, setExistingRooms] = useState([]);
  const [isExistingRoomsOpen, setIsExistingRoomsOpen] = useState(false);

  const isSelectTypesOfRoomToJoinOpenRef = useRef(
    isSelectTypesOfRoomToJoinOpen,
  );
  const isSelectTypesOfRoomToStartOpenRef = useRef(
    isSelectTypesOfRoomToStartOpen,
  );

  const startJoinRef = useRef(null); // Create a ref for the div

  const openSelectTypesOfRoomToStart = useCallback(() => {
    isSelectTypesOfRoomToStartOpenRef.current = true;
    setSelectTypesOfRoomToStartOpen(true);
  }, []);

  const closeSelectTypesOfRoomToStart = useCallback(() => {
    isSelectTypesOfRoomToStartOpenRef.current = false;
    setSelectTypesOfRoomToStartOpen(false);
  }, []);

  useEffect(() => {
    setIsExistingRoomsOpen(false);
  }, [isSelectTypesOfRoomToJoinOpen, isSelectTypesOfRoomToStartOpen]);

  const openSelectTypesOfRoomToJoin = useCallback(() => {
    isSelectTypesOfRoomToJoinOpenRef.current = true;
    setSelectTypesOfRoomToJoinOpen(true);
  }, []);

  const closeSelectTypesOfRoomToJoin = useCallback(() => {
    isSelectTypesOfRoomToJoinOpenRef.current = false;
    setSelectTypesOfRoomToJoinOpen(false);
  }, []);

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        startJoinRef.current &&
        !startJoinRef.current.contains(event.target) &&
        !isSelectTypesOfRoomToJoinOpenRef.current &&
        !isSelectTypesOfRoomToStartOpenRef.current
      ) {
        onOutsideClickFunc();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onOutsideClickFunc]);

  const navigate = useNavigate();

  return (
    <div className='fixed inset-0 z-50 bg-overlay-new-background flex items-center justify-center text-left text-base text-updated-color-new-black font-montserrat'>
      {!isSelectTypesOfRoomToJoinOpen && !isSelectTypesOfRoomToStartOpen && (
        <div className={`flex justify-center h-fit p-10 w-1/3`}>
          <div
            ref={startJoinRef}
            className='rounded-lg cursor-pointer bg-gray-200 shadow-[0_3px_5.5px_rgba(140,_140,_140,_0.25)] w-full overflow-hidden flex flex-col items-center justify-center py-[15px] box-border'
          >
            <div
              className='flex items-center justify-center py-5 px-10 w-fit'
              onClick={openSelectTypesOfRoomToStart}
            >
              <div className='font-medium w-full text-center'>Start a Room</div>
            </div>
            {/* Separator Line with increased visibility */}
            <div className='w-11/12 h-[.5px] bg-black my-2'></div>
            <div
              className='flex items-center justify-center py-5 px-10 cursor-pointer w-full'
              onClick={openSelectTypesOfRoomToJoin}
            >
              <div className='font-medium w-full text-center'>Join a Room</div>
            </div>
            <div className='w-11/12 h-[.5px] bg-black my-2'></div>
            <div
              className='flex items-center justify-center py-5 px-10 cursor-pointer w-full'
              onClick={() => {
                setIsExistingRoomsOpen((prev) => !prev);
                if (!isExistingRoomsOpen) {
                  fetch(`${serverURL}/rooms`, {
                    method: 'GET',
                    headers: {
                      Authorization: `Bearer ${jwtTokenRef.current}`,
                    },
                  })
                    .then((res) => {
                      if (res.ok) {
                        return res.json();
                      }
                      throw new Error('Network response was not ok');
                    })
                    .then((data) => setExistingRooms(data))
                    .catch((error) =>
                      console.error('Error fetching rooms:', error),
                    );
                }
              }}
            >
              <div className='font-medium w-full text-center'>
                Manage Existing Rooms
              </div>
            </div>
            {isExistingRoomsOpen && existingRooms.length > 0 && (
              <div className='w-full max-h-48 overflow-y-auto'>
                {existingRooms.map((room) => (
                  <div key={room.id} className='flex items-center w-11/12 my-2'>
                    <div
                      className='relative left-[30px] font-medium truncate text-left w-full'
                      onClick={() => {
                        navigate(`/broadcast-host/${room.id}`);
                      }}
                    >
                      {room.name}
                    </div>
                    <button
                      className='text-red-500 ml-4' // Moved the margin to the button
                      style={{ position: 'relative', right: '0px' }} // Ensures the delete button is always in the same place
                      onClick={() => {
                        // Add your delete logic here
                        fetch(`${serverURL}/rooms/${room.id}`, {
                          method: 'DELETE',
                          headers: {
                            Authorization: `Bearer ${jwtTokenRef.current}`,
                          },
                        }).then((res) => {
                          if (res.ok) {
                            // Optionally update the existingRooms state to remove the deleted room
                            setExistingRooms(
                              existingRooms.filter((r) => r.id !== room.id),
                            );
                          }
                        });
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {isSelectTypesOfRoomToJoinOpen && (
        <SelectTypesOfRoomToJoin
          onClose={closeSelectTypesOfRoomToJoin}
          onOutsideClickFunc={closeSelectTypesOfRoomToJoin}
        />
      )}
      {isSelectTypesOfRoomToStartOpen && (
        <SelectTypesOfRoomToStart
          onClose={closeSelectTypesOfRoomToStart}
          onOutsideClickFunc={closeSelectTypesOfRoomToStart}
        />
      )}
    </div>
  );
};

export default StartjoinARoomOverlay;
