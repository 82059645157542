import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import PortalPopup from './PortalPopup';
import { jwtTokenRef, serverURL } from '../../httpContext';
import { isToday, isYesterday, subDays, parseISO } from 'date-fns';

const PopupTemplate = ({ className = '', imageSrc, title, description }) => {
  return (
    <div
      className={`w-full relative bg-updated-color-mobile-overlay-new-background h-full overflow-hidden max-w-full max-h-full text-left text-lg text-updated-color-new-black font-montserrat ${className}`}
    >
      <div className='absolute top-[calc(50%_-_68.5px)] left-[calc(50%_-_99.5px)] rounded-3xl bg-whitesmoke flex flex-col items-center justify-center pt-[35px] px-[60px] pb-5 gap-[10px]'>
        <img className='w-[45px] relative h-[50px]' alt='' src={imageSrc} />
        <div className='flex flex-col items-start justify-start'>
          <div className='flex flex-col items-center justify-start'>
            <div className='relative font-semibold'>{title}</div>
            {description && (
              <div className='relative text-sm text-center'>{description}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DeleteFromHistory = ({ className = '', onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const timeoutRef = useRef(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) {
      timeoutRef.current = setTimeout(onClose, 0);
    }
  }, [isVisible, onClose]);

  return (
    <PopupTemplate
      className={`${className} ${isVisible ? 'fade-out' : 'fade-out hidden'}`}
      imageSrc='/delete.svg'
      title='Deleted!'
    />
  );
};

const SaveFromHistory = ({ className = '' }) => (
  <PopupTemplate
    className={className}
    imageSrc='/interface--check-circle-1.svg'
    title='Saved!'
    description={
      <>
        <span>You can view it from </span>
        <i>Doc - Recently Saved</i>
      </>
    }
  />
);

const RenamePopup = ({ className = '', onClose, fileName, onRename }) => {
  const [inputValue, setInputValue] = useState(fileName);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleRename = () => {
    onRename(inputValue);
  };

  return (
    <div
      className={`w-full relative bg-updated-color-mobile-overlay-new-background h-full overflow-hidden max-w-full max-h-full text-left text-lg text-updated-color-new-black font-montserrat ${className}`}
    >
      <div className='absolute top-[calc(50%_-_109.5px)] left-[calc(50%_-_170.5px)] rounded-xl bg-whitesmoke w-[340px] flex flex-col items-start justify-start pt-[45px] px-[35px] pb-5 box-border gap-[35px]'>
        <div className='flex flex-col items-start justify-start gap-[20px]'>
          <div className='flex flex-col items-start justify-start'>
            <div className='relative font-semibold'>Rename</div>
          </div>
          <div className='w-[270px] flex flex-row items-center justify-start gap-[10px] text-center text-sm'>
            <input
              className='w-[270px] flex flex-row items-center justify-start py-2.5 px-0 relative gap-[10px] border-b-[0.5px] border-x-0 border-t-0 border-solid border-updated-color-grey2 bg-transparent outline-none'
              type='text'
              value={inputValue}
              onChange={handleInputChange}
              ref={inputRef}
            />
          </div>
        </div>
        <div
          className={`w-[270px] rounded-3xs ${
            inputValue
              ? 'bg-[linear-gradient(102.81deg,_rgba(0,_126,_243,_0.6)_0%,_rgba(121,_119,_209,_0.62)_100%)]'
              : 'bg-updated-color-grey2'
          } flex flex-row items-center justify-center py-2.5 px-[104px] box-border text-base text-white cursor-pointer`}
          onClick={handleRename}
        >
          <div className='relative font-medium'>OK</div>
        </div>
        <img
          className='w-6 absolute top-[10px] left-[305.5px] h-6 overflow-hidden cursor-pointer'
          alt=''
          src='/interface--close-circle-11.svg'
          onClick={onClose}
        />
      </div>
    </div>
  );
};

const ThreeDotsMenu = ({ onRename, onDelete, onClose }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  return (
    <div
      ref={menuRef}
      className='absolute z-20 right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
    >
      <div className='py-1' role='none'>
        <button
          className='text-gray-700 block px-4 py-2 text-sm'
          onClick={onRename}
        >
          Rename
        </button>
        <button
          className='text-gray-700 block px-4 py-2 text-sm'
          onClick={onDelete}
        >
          Delete
        </button>
        <button
          className='text-gray-700 block px-4 py-2 text-sm'
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const groupTranscriptsByDate = (transcripts) => {
  const grouped = {
    today: [],
    yesterday: [],
    lastWeek: [],
    previous30Days: [],
  };

  transcripts.forEach((transcript) => {
    const transcriptDate = parseISO(transcript.created_at);
    if (isToday(transcriptDate)) {
      grouped.today.push(transcript);
    } else if (isYesterday(transcriptDate)) {
      grouped.yesterday.push(transcript);
    } else if (transcriptDate > subDays(new Date(), 7)) {
      grouped.lastWeek.push(transcript);
    } else {
      grouped.previous30Days.push(transcript);
    }
  });

  return grouped;
};

const RecentsSidebar = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [recentFiles, setRecentFiles] = useState([]);
  const [isRenameOpen, setRenameOpen] = useState(false);
  const [isSaveOpen, setSaveOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [currentFileName, setCurrentFileName] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const windowRef = useRef(null);
  const scrollEndRef = useRef(null);
  const observer = useRef(null);
  const pageRef = useRef(2);
  const isFetchingRef = useRef(false);
  const searchTimeoutRef = useRef(null);

  // Detect clicks outside the ref'ed div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        windowRef.current &&
        !windowRef.current.contains(event.target) &&
        !isRenameOpen
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose, isRenameOpen]);

  const fetchFiles = async (query = '', page = 1) => {
    try {
      await jwtTokenRef.current;
      const response = await axios.get(`${serverURL}/list_transcripts`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
        params: {
          folder_id: null,
          page_size: 10,
          page_number: page,
          get_raw_transcript: false,
          text_search: query,
        },
      });

      const data = response.data.transcripts;
      const retrievedFiles = data.filter((file) => file.transcript !== null);
      if (page === 1) {
        setRecentFiles(retrievedFiles);
      } else {
        setRecentFiles((prevFiles) => [...prevFiles, ...retrievedFiles]);
      }
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    const options = {
      root: windowRef.current,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isFetchingRef.current) {
        loadMoreFiles();
      }
    }, options);

    const target = scrollEndRef.current;
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current && target) {
        observer.current.unobserve(target);
      }
    };
  }, []);

  const loadMoreFiles = async () => {
    isFetchingRef.current = true;
    try {
      await jwtTokenRef.current;
      const response = await axios.get(`${serverURL}/list_transcripts`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
        params: {
          folder_id: null,
          page_size: 10,
          page_number: pageRef.current,
          get_raw_transcript: false,
          text_search: searchQuery,
        },
      });

      const data = response.data.transcripts;
      const retrievedFiles = data.filter(
        (file) => file.transcript_type !== 'gpu' || file.transcript !== null,
      );
      if (retrievedFiles.length > 0) {
        setRecentFiles((prevFiles) => [...prevFiles, ...retrievedFiles]);
        pageRef.current += 1;
      }
    } catch (error) {
      console.error('Error loading more files:', error);
    }
    isFetchingRef.current = false;
  };

  const groupedFiles = groupTranscriptsByDate(recentFiles);

  const togglePopup = (setter) => () => setter((prev) => !prev);

  const openMenu = (index, fileName, file) => {
    setOpenMenuIndex(index);
    setCurrentFileName(fileName);
    setCurrentFile(file);
  };
  const closeMenu = () => setOpenMenuIndex(null);

  const handleRename = () => {
    setRenameOpen(true);
    closeMenu();
  };

  const handleDelete = async (fileToRemove) => {
    try {
      await fetch(
        `${serverURL}/delete_transcript?transcript_id=${fileToRemove.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      setRecentFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== fileToRemove.id),
      );
      setDeleteOpen(true);
      closeMenu();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleFileRename = async (newName) => {
    try {
      await fetch(
        `${serverURL}/rename_transcript?transcript_id=${currentFile.id}&new_name=${newName}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      setRenameOpen(false);
      setRecentFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === currentFile.id ? { ...file, name: newName } : file,
        ),
      );
    } catch (error) {
      console.error('Error renaming file:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      fetchFiles(e.target.value);
    }, 1000);
  };

  return (
    <div className='top-0 right-0 z-10 w-[320px] absolute bg-whitesmoke h-full overflow-hidden text-left text-sm text-updated-color-new-black font-montserrat'>
      <div
        ref={windowRef}
        className='absolute top-0 left-0 bg-whitesmoke-100 w-80 h-full overflow-y-auto'
      >
        <div className='absolute top-[192px] left-[24px] flex flex-col items-start justify-start gap-[25px] text-updated-color-grey2'>
          {Object.entries(groupedFiles).map(([timePeriod, files]) => (
            <div
              key={timePeriod}
              className='flex flex-col items-start justify-start gap-[25px] relative'
            >
              <div className='w-[272px] relative leading-[22px] font-medium inline-block'>
                {(timePeriod === 'lastWeek' && 'Last Week') ||
                  (timePeriod === 'previous30Days' && 'Previous 30 Days') ||
                  timePeriod.charAt(0).toUpperCase() + timePeriod.slice(1)}
              </div>
              {files.map((file) => (
                <div
                  key={file.id}
                  className='w-[272px] flex flex-col items-start justify-start gap-[15px] relative'
                >
                  <img
                    className='w-[272px] relative max-h-full'
                    alt=''
                    src='/vector-373.svg'
                  />
                  <div className='flex flex-col items-start justify-start gap-[10px] text-updated-color-new-black relative'>
                    <div
                      className='w-[272px] relative leading-[22px] font-semibold inline-block'
                      onClick={() => {
                        navigate(`/file_transcript?id=${file.id}`, {
                          state: { from: location.pathname },
                        });
                      }}
                    >
                      <div className='flex justify-between items-center'>
                        {file.name}
                        <div className='relative'>
                          <div
                            className='w-6 h-6 flex items-center justify-center cursor-pointer'
                            onClick={(e) => {
                              e.stopPropagation();
                              openMenu(file.id, file.name, file);
                            }}
                          >
                            <div className='flex flex-row items-center justify-center gap-[2px]'>
                              <div className='w-[3.5px] h-[3.5px] rounded-full bg-updated-color-new-black'></div>
                              <div className='w-[3.5px] h-[3.5px] rounded-full bg-updated-color-new-black'></div>
                              <div className='w-[3.5px] h-[3.5px] rounded-full bg-updated-color-new-black'></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {openMenuIndex === file.id && (
                      <ThreeDotsMenu
                        onRename={handleRename}
                        onDelete={() => handleDelete(file)}
                        onClose={closeMenu}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
          <div ref={scrollEndRef}></div>
        </div>
        <div className='absolute top-[135px] left-[20px] rounded-xl bg-updated-color-grey1 w-[280px] flex flex-row items-center justify-start py-1 pr-[258px] pl-2 box-border gap-[10px] text-base text-updated-color-grey2'>
          <img
            className='w-6 relative h-6 overflow-hidden shrink-0'
            alt=''
            src='/general--search.svg'
          />
          <input
            type='text'
            placeholder='search'
            className='bg-transparent outline-none border-none'
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className='absolute top-[80px] left-[24px] text-[12px] leading-[22px] font-medium'>
          * Your auto-saved documents will be stored for 30 days only
        </div>
      </div>
      {isRenameOpen && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
          onOutsideClick={togglePopup(setRenameOpen)}
        >
          <RenamePopup
            onClose={togglePopup(setRenameOpen)}
            fileName={currentFileName}
            onRename={handleFileRename}
          />
        </PortalPopup>
      )}
      {isSaveOpen && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
          onOutsideClick={togglePopup(setSaveOpen)}
        >
          <SaveFromHistory />
        </PortalPopup>
      )}
      {isDeleteOpen && (
        <PortalPopup
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Centered'
          onOutsideClick={togglePopup(setDeleteOpen)}
        >
          <DeleteFromHistory
            onClick={(e) => {
              e.stopPropagation();
            }}
            onClose={togglePopup(setDeleteOpen)}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default RecentsSidebar;
