import { Link } from 'react-router-dom';

const FrameComponent1 = () => {
  return (
    <div className='py-6 gap-[2rem] flex flex-col'>
      <div className='flex justify-center mb-4'>
        <div className='flex gap-[0.375rem]'>
          <div className='h-[0.3125rem] w-[0.4375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]' />
          <div className='h-[0.3125rem] w-[0.4375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]' />
          <div className='h-[0.3125rem] w-[0.4375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]' />
          <div className='h-[0.3125rem] w-[1.375rem] relative rounded [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)]' />
        </div>
      </div>
      <div className='text-center'>
        <div className='flex flex-col gap-1 mb-12'>
          <p className='font-semibold text-lg'>Universal Translation Tool</p>
          <p className='text-[#7B7B7B]'>
            Navigate and communicate across languages smoothly.
          </p>
        </div>
      </div>
      <div className='flex justify-between items-center px-[1.25rem]'>
        <Link
          to='/signup'
          onClick={() => {
            localStorage.setItem('isOnboarded', 'true');
          }}
          className='leading-[1.5rem] text-[1rem] text-updated-color-new-black font-medium min-w-[2.25rem] tracking-[0.1px]'
        >
          Skip
        </Link>
        <Link
          to='/signup'
          onClick={() => {
            localStorage.setItem('isOnboarded', 'true');
          }}
          className='leading-[1.5rem] text-[1rem] text-updated-color-new-black font-medium min-w-[2.25rem] tracking-[0.1px]'
        >
          Start
        </Link>
      </div>
    </div>
  );
};

export default FrameComponent1;
