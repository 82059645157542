import { useState, useEffect } from 'react';
import { serverURL, jwtTokenRef } from '../../httpContext';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Files } from './Files';

export const MobileFolderFiles = () => {
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const [folderName, setFolderName] = useState('');

  const processFile = (file) => {
    return {
      name: file.name,
      content: file.transcript,
      created_at: file.created_at,
      path: file.path,
      id: parseInt(file.id),
      raw_transcript: file.raw_transcript,
      transcript_type: file.transcript_type,
    };
  };

  const refreshFiles = async () => {
    const folderId = new URLSearchParams(location.search).get('id');
    try {
      const response = await axios.get(
        `${serverURL}/list_transcripts?folder_id=${folderId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      const data = response.data.transcripts;
      const retrievedFiles = data.map((file) => processFile(file));
      setFiles(retrievedFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const getFolder = async () => {
    const folderId = new URLSearchParams(location.search).get('id');
    try {
      const response = await axios.get(
        `${serverURL}/get_folder?folder_id=${folderId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        },
      );
      const name = response.data.name;
      setFolderName(name);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  useEffect(() => {
    refreshFiles();
    getFolder();
  }, []);

  const navigate = useNavigate();

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        transition={{ type: 'tween', duration: 0.25 }}
        className='absolute z-20 h-full pt-16 flex-col justify-center bg-whitesmoke ml-[20px] mr-[20px] rounded-lg overflow-y-scroll'
      >
        <img
          className='w-[2.25rem] h-[2.25rem] relative shrink-0'
          loading='lazy'
          alt=''
          src='/arrow-left.svg'
          onClick={() => {
            navigate(-1);
          }}
        />
        <Files
          title={folderName}
          recentFiles={files}
          refreshFiles={refreshFiles}
        ></Files>
      </motion.div>
    </AnimatePresence>
  );
};
