import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { serverURL } from '../../httpContext';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] = useState('password');

  const togglePassword = () => {
    setShowPassword(!showPassword);
    setType(type === 'password' ? 'text' : 'password');
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    const verificationCodeParam = params.get('verification_code');

    if (emailParam) setEmail(emailParam);
    if (verificationCodeParam) setVerificationCode(verificationCodeParam);
  }, [location]);

  const onGroupIconClick = useCallback(() => {
    navigate('/signup');
  }, [navigate]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${serverURL}/change-password-forgot`, {
        email: email,
        new_password: password,
        email_verification_code: verificationCode,
      });

      if (response.data) {
        setSuccess(true);
      }
    } catch (error) {
      setError('Failed to reset password. Please try again.');
    }
  };

  const handleReturnToLogin = () => {
    navigate('/login');
  };

  return (
    <div className='h-screen bg-white relative flex max-w-[1512px] w-full mx-auto font-montserrat'>
      {/* Header */}
      <nav className='mb-8 flex justify-between items-center absolute top-10 left-20'>
        <div className='flex items-center gap-2'>
          <img
            className='h-10 w-10'
            alt='Logo'
            src='/1024px-x-1024px-round-w-text1.svg'
          />
          <span className='text-updated-color-blue text-xl md:text-2xl font-medium tracking-tight'>
            interpret
          </span>
        </div>
      </nav>
      {/* Left Section */}
      <div className='p-8 pt-44 px-20 pr-0 w-full flex gap-28'>
        <img
          onClick={onGroupIconClick}
          className='w-8 h-8 cursor-pointer'
          alt='Back'
          src='/group-1707478204.svg'
        />
        {/* Main Content */}
        <div className='space-y-8 flex flex-col'>
          {!success && (
            <div className='flex flex-col gap-6'>
              <div className='text-5xl font-semibold leading-tight'>
                {success ? 'Password successfully changed!' : 'Reset Password'}
              </div>
              {!success && (
                <div className='text-unselected-text'>
                  You're a step away from accessing your account.
                </div>
              )}
              {/* Password Input */}
              <div className='relative mt-20'>
                <div className='flex items-center gap-2'>
                  <img className='w-7 h-7' alt='' src='/general--lock1.svg' />
                  <input
                    type={type}
                    placeholder='Enter your password'
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleSubmit();
                    }}
                    className='text-base w-full py-2.5 px-2 border-b border-solid border-x-0 border-t-0 border-updated-color-grey1 font-montserrat focus:outline-none'
                    disabled={success}
                  />
                  <button
                    type='button'
                    onClick={togglePassword}
                    className='absolute right-2 top-2'
                  >
                    {showPassword ? (
                      <EyeInvisibleOutlined className='text-2xl text-gray-400' />
                    ) : (
                      <EyeOutlined className='text-2xl text-gray-400' />
                    )}
                  </button>
                </div>
              </div>

              {/* Confirm Password Input */}
              <div className='relative'>
                <div className='flex items-center gap-2'>
                  <img className='w-7 h-7' alt='' src='/general--lock1.svg' />
                  <input
                    type={type}
                    placeholder='Confirm your password'
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleSubmit();
                    }}
                    className='w-full text-base py-2.5 px-2 border-b border-solid border-x-0 border-t-0 border-updated-color-grey1 font-montserrat focus:outline-none'
                    disabled={success}
                  />
                  <button
                    type='button'
                    onClick={togglePassword}
                    className='absolute right-2 top-2'
                  >
                    {showPassword ? (
                      <EyeInvisibleOutlined className='text-2xl text-gray-400' />
                    ) : (
                      <EyeOutlined className='text-2xl text-gray-400' />
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Error Message */}
          {error && <div className='text-red-600'>{error}</div>}

          {/* Submit Button */}
          <button
            onClick={success ? handleReturnToLogin : handleSubmit}
            className='w-full bg-updated-color-blue text-white py-3 px-4 rounded-lg font-semibold text-xl hover:bg-blue-600 transition-colors'
          >
            {success ? 'Return to Login' : 'Reset'}
          </button>
        </div>
      </div>

      {/* Right Section - Grey Background */}
      <div className='w-full bg-updated-color-grey1' />
    </div>
  );
};

export default ResetPassword;
