import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  serverURL,
  jwtTokenRef,
  userInfoRef,
  updateAccountInfo,
} from '../../httpContext';
import {
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';

const ChangeForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const onArrowArrowLeft6CircleClick = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const handleChangePasswordForgot = async (e) => {
    e.preventDefault();
    if (email === '' || password1 === '' || password2 === '') {
      setErrorMessage('Please fill in all fields');
      return;
    }

    if (password1 !== password2) {
      setErrorMessage('Passwords do not match');
      return;
    }

    const response = await fetch(`${serverURL}/change-password-forgot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        email_verification_code: verificationCode,
        new_password: password1,
      }),
    });

    const response_json = await response.json();

    if (response.status === 200) {
      jwtTokenRef.current = response_json.access_token;
      try {
        localStorage.setItem('jwtToken', jwtTokenRef.current);
        updateAccountInfo(jwtTokenRef, userInfoRef);
        console.log('Saved jwtToken');
      } catch (e) {
        console.log(e);
        setErrorMessage('Internal server error');
        return;
      }

      setErrorMessage('');
      navigate('/');
    } else {
      setErrorMessage('Invalid password');
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setEmail(urlParams.get('email'));
    setVerificationCode(urlParams.get('verification_code'));
  }, []);

  return (
    <div className='w-full h-ful flex flex-col pt-[4.375rem] px-[1.25rem] pb-[21.375rem] box-border gap-[1.875rem] leading-[normal] tracking-[normal]'>
      <img
        className='w-[2.5rem] h-[2.5rem] relative overflow-hidden shrink-0 cursor-pointer'
        loading='lazy'
        src='/mobileback.svg'
        onClick={onArrowArrowLeft6CircleClick}
      />
      <section className='self-stretch flex flex-row items-start justify-start py-[0rem] px-[0.312rem] box-border max-w-full text-left text-[1.125rem] text-updated-color-new-black font-montserrat'>
        <div className='flex-1 flex flex-col items-start justify-start gap-[9.25rem] max-w-full'>
          <div className='self-stretch flex flex-col items-start justify-start gap-[0.875rem]'>
            <div className='relative font-semibold'>Reset Password</div>
            <div className='relative text-[0.875rem] text-unselected-text'>
              You’re a step away from accessing your account.
            </div>
          </div>
          <form className='m-0 self-stretch flex flex-col gap-[2.812rem]'>
            <div className='self-stretch flex flex-col gap-[0.625rem]'>
              <div className='self-stretch flex flex-wrap items-center gap-[0.625rem]'>
                <LockOutlined className='text-lg text-black relative overflow-hidden shrink-0' />
                <div className='flex-1 border-0 flex items-center pt-[0.437rem] px-[0rem] pb-[0.375rem] relative gap-[0.625rem] min-w-[9rem] min-h-[2.313rem] border-b-[0.5px] border-solid border-updated-color-grey2'>
                  <input
                    className='w-full border-none [outline:none] font-montserrat text-sm bg-transparent text-updated-color-new-black inline-block p-0'
                    placeholder='Enter your password'
                    type='text'
                    value={password1}
                    autoComplete='new-password'
                    onChange={(e) => setPassword1(e.target.value)}
                  />
                  {showPassword ? (
                    <EyeOutlined
                      className='text-lg text-black absolute right-0 overflow-hidden shrink-0 cursor-pointer'
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      className='text-lg text-black absolute right-0 overflow-hidden shrink-0 cursor-pointer'
                      onClick={() => setShowPassword(true)}
                    />
                  )}
                </div>
              </div>
              <div className='self-stretch flex flex-row flex-wrap items-center gap-[0.625rem]'>
                <LockOutlined className='h-[1.25rem] w-[1.25rem] relative overflow-hidden shrink-0' />
                <div className='flex-1 border-0 flex items-center pt-[0.437rem] px-[0rem] pb-[0.375rem] relative gap-[0.625rem] min-w-[10.188rem] min-h-[2.313rem] border-b-[0.5px] border-solid border-updated-color-grey2'>
                  <input
                    className='w-full border-none [outline:none] font-montserrat text-sm bg-transparent text-updated-color-new-black inline-block p-0'
                    placeholder='Confirm your password'
                    type='text'
                    value={password2}
                    autoComplete='new-password'
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                  {showPassword ? (
                    <EyeOutlined
                      className='text-lg text-black absolute right-0 overflow-hidden shrink-0 cursor-pointer'
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      className='text-lg text-black absolute right-0 overflow-hidden shrink-0 cursor-pointer'
                      onClick={() => setShowPassword(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            <button
              className='[border:none] py-[0.625rem] px-[1.25rem] bg-[transparent] self-stretch rounded-3xs [background:linear-gradient(102.81deg,_#007ef3,_#7977d1)] flex flex-row items-center justify-center'
              onClick={handleChangePasswordForgot}
            >
              <div className='relative text-[1rem] font-semibold font-montserrat text-white text-center inline-block min-w-[2.938rem]'>
                Reset
              </div>
            </button>
            {errorMessage && (
              <p className='text-red-500 text-lg text-center w-full'>
                {errorMessage}
              </p>
            )}
          </form>
        </div>
      </section>
    </div>
  );
};

export default ChangeForgotPassword;
