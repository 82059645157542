export const Privacy = () => {
  return (
    <div className='h-screen pt-28 bg-white font-montserrat flex flex-col gap-14 px-10 pb-20 sm:px-48'>
      <div className='text-[40px] font-semibold text-not-black'>
        Privacy Policy
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Introduction
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6'>
          Welcome to Interpret AI. This Privacy Policy outlines how we collect,
          use, disclose, and safeguard your personal information when you use
          our services on the web or mobile applications.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Information We Collect
        </div>
        <ul className='text-updated-color-grey2 font-medium text-sm leading-6'>
          <li>
            User Provided Information: When you use our services, we may collect
            your name and email address to register your account.
          </li>
          <li>
            Automatically Collected Information: We may automatically collect
            certain information, including device type, model, OS version, usage
            data, and analytics such as the amount of minutes used and
            timestamps of transcriptions.
          </li>
        </ul>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          How We Use Your Information
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6 mb-4'>
          We use the collected information for the following purposes:
        </div>
        <ol className='text-updated-color-grey2 font-medium text-sm leading-6'>
          <li>To provide and improve our services.</li>
          <li>To enhance user experience.</li>
          <li>To manage user accounts and provide customer support.</li>
        </ol>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Sharing of Information
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6 mb-4'>
          We do not sell, trade, or rent your personal information to third
          parties. However, we may share information in the following cases:
        </div>
        <ol className='text-updated-color-grey2 font-medium text-sm leading-6'>
          <li>To comply with legal obligations.</li>
          <li>To protect our rights or the rights of others.</li>
        </ol>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Security
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6 mb-4'>
          We prioritize the security of your information and use
          industry-standard measures to protect it. However, no method of
          transmission over the internet or electronic storage is 100% secure.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Refund Policy
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6 mb-4'>
          We offer refunds on any subscription purchased and not used within a
          24-hour window from the time of purchase. If in the case a refund is
          requested after the app was used within the 24-hour window of
          purchase, our customer support team may decide to approve or deny the
          refund request.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Account Deletion and Available Settings
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6 mb-4'>
          You can control the information you provided by accessing the account
          settings. There, the ability to edit account information and delete
          your account will be available. You may also contact our support team
          for further assistance.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Changes to This Privacy Policy
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6 mb-4'>
          We reserve the right to update our Privacy Policy. Any changes will be
          effective immediately upon posting the updated policy. To update users
          of any Privacy Policy changes, we will provide an email to the users
          registered email upon Privacy Policy change.
        </div>
      </div>
      <div>
        <div className='text-xl text-not-black font-semibold mb-2'>
          Contact Us
        </div>
        <div className='text-updated-color-grey2 font-medium text-sm leading-6 mb-4'>
          If you have uestions or concerns about this Privacy Policy, please
          contact us at Support@InterpretApp.AI
        </div>
      </div>
    </div>
  );
};
