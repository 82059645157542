// reads the languages.json list and parses it.
import languages from './languages.json';
console.log(languages);
var top100WorldLanguages = [];
for (var i = 0; i < languages.length; i++) {
  top100WorldLanguages.push(languages[i]['English']);
}

var languageToCode = {};
for (var i = 0; i < languages.length; i++) {
  languageToCode[languages[i]['English']] = languages[i]['alpha2'];
}

var fileTranslateLanguages = [
  'English',
  'Chinese (Simplified)',
  'Spanish',
  'Hindi',
  'Arabic',
  'Bengali',
  'Portuguese',
  'Russian',
  'Japanese',
  'Turkish',
  'French',
  'German',
  'Korean',
  'Vietnamese',
  'Italian',
  'Tagalog',
  'Thai',
  'Malay',
  'Persian',
  'Ukrainian',
  'Dutch',
  'Swahili',
  'Filipino (Tagalog)',
  'Polish',
  'Romanian',
  'Hungarian',
  'Czech',
  'Greek',
  'Bulgarian',
  'Finnish',
  'Swedish',
  'Norwegian',
  'Danish',
  'Serbian (Cyrillic letters)',
  'Serbian (Latin letters)',
  'Uighur',
  'Kazakh',
  'Amharic',
  'Armenian',
  'Azerbaijani',
  'Georgian',
  'Latvian',
  'Lithuanian',
  'Macedonian',
  'Croatian',
  'Slovak',
  'Slovenian',
  'Estonian',
  'Burmese',
  'Malayalam',
  'Marathi',
  'Punjabi',
  'Tamil',
  'Telugu',
  'Sinhala',
  'Khmer',
  'Haitian Creole',
  'Hebrew',
  'Yiddish',
  'Cantonese',
  'Lao',
  'Maltese',
  'Icelandic',
  'Irish',
  'Latin',
  'Chavacano',
  'Norwegian',
  'Mongolian (Cyrillic)',
  'Tigrinya',
  'Uzbek',
];

console.log(top100WorldLanguages);

export { top100WorldLanguages, languageToCode, fileTranslateLanguages };
