import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { userInfoRef } from '../httpContext';

const Nav = ({ showMobile }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const checkEmailVerification = () => {
      if (userInfoRef.current?.verified_email) {
        setIsUserLoggedIn(true);
        clearInterval(interval);
      }
    };

    const interval = setInterval(checkEmailVerification, 1000);

    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();

  return (
    <div className='fixed top-0 left-0 w-full bg-white/90 z-20 text-updated-color-blue font-montserrat'>
      <div className='flex px-4 md:px-16 mx-auto 2xl:px-0 items-center sm:gap-0 gap-5 py-8 pb-6 justify-between max-w-[1512px]'>
        <div
          className='md:pl-2 cursor-pointer flex items-center gap-[5px]'
          onClick={() => {
            navigate('/');
          }}
        >
          <img
            className='h-6 sm:h-12 w-6 sm:w-12'
            alt=''
            src='/1024px-x-1024px-round-w-text1.svg'
          />
          <div className='tracking-[-0.02em] font-medium text-md sm:text-2xl'>
            interpret
          </div>
        </div>
        <div className='w-fit flex items-center gap-4 sm:gap-[50px] text-xs sm:text-xl text-not-black-black'>
          {!showMobile && (
            <div
              className='flex flex-row items-center justify-center py-[5px] cursor-pointer'
              onClick={() => {
                navigate('/plan');
              }}
            >
              <div className='font-medium'>Pricing</div>
            </div>
          )}
          <div
            className='flex items-center justify-center py-[5px] cursor-pointer'
            onClick={() => {
              if (userInfoRef.current?.verified_email) navigate('/transcribe');
              else {
                navigate('/login');
              }
            }}
          >
            <div className='font-medium'>
              {isUserLoggedIn ? 'Dashboard' : 'Login'}
            </div>
          </div>
          <div
            className='font-medium cursor-pointer'
            onClick={() => navigate('/contact')}
          >
            Contact Us
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
