import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Homepage from './pages/web2/Homepage';
import { useEffect, useState } from 'react';
import * as HttpContext from './httpContext';
import { jwtTokenRef, userInfoRef } from './httpContext';
import LearnMoreContainer from './components/LearnMoreContainer';
import Plan from './pages/web2/Plan';
import Login from './pages/web2/Login';
import Signup from './pages/web2/Signup';
import EmailVerification from './pages/web2/EmailVerification';
import ForgotPassword from './pages/web2/ForgotPassword';
import ResetPassword from './pages/web2/ResetPassword';
import Transcribe from './pages/web2/Transcribe';
import FileTranslate from './pages/web2/FileTranslate';
import AudioTranslate from './pages/web2/AudioTranslate';
import Docs from './pages/web2/Docs';
import ProfileAccountInfo from './pages/web2/ProfileAccountInfo';
import ProfileCurrentPlan from './pages/web2/ProfileCurrentPlan';
import ProfileBuyPlan from './pages/web2/ProfileBuyPlan';
import FileTranscript from './pages/web2/FileTranscript';
import BroadcastRoom from './pages/web2/BroadcastRoom2';
import { Privacy } from './pages/web2/Privacy';
import { TOS } from './pages/web2/TOS';
import Nav from './components/Nav';
import { Contact } from './pages/web2/Contact';
import { SpeakerRoom } from './pages/web2/SpeakerRoom';
import ProfileDropdown from './components/ProfileDropdown';
import { ZoomInstructions } from './pages/web2/ZoomInstructions';

function AppWeb() {
  const navigate = useNavigate();
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const [loginRegisterPopupVisible, setLoginRegisterPopupVisible] =
    useState(false);
  const [showHome, setShowHome] = useState(true);
  HttpContext.setSetIsLoggedInStateFunc(setLoginRegisterPopupVisible);

  const refreshTokenAndAccountInfo = async () => {
    console.log('JWT token: ' + jwtTokenRef.current);
    console.log('userInfo: ' + userInfoRef.current);

    if (jwtTokenRef.current != undefined) {
      console.log('JWT token exists');
      if (HttpContext.isJwtExpired(jwtTokenRef.current)) {
        console.log('JWT token expired');
        jwtTokenRef.current = undefined;
        localStorage.removeItem('jwtToken');
        return;
      }

      await HttpContext.refreshJWTToken(jwtTokenRef);

      await HttpContext.updateAccountInfo(jwtTokenRef, userInfoRef);

      if (isVerifiedUser()) {
        console.log('Verified email');
        setLoginRegisterPopupVisible(false);
        if (pathname !== '/' && pathname !== '/plan' && pathname !== '/login')
          setShowHome(false);
      } else {
        if (!pathname.includes('/broadcast')) {
          setLoginRegisterPopupVisible(true);
        }
      }
    } else {
      if (!loginRegisterPopupVisible) return;
      else if (pathname.includes('/') || pathname.includes('/buy-credits')) {
        setLoginRegisterPopupVisible(false);
      } else if (!pathname.includes('/broadcast')) {
        setLoginRegisterPopupVisible(true);
      }
    }
  };

  const handleVerifyEmail = async (email, verificationCode) => {
    var response = await fetch(
      `${HttpContext.serverURL}/verify-email?email=${email}&verification_code=${verificationCode}`,
      {
        method: 'GET',
      },
    );

    var response_json = await response.json();

    if (response.status === 200) {
      jwtTokenRef.current = response_json.access_token;
      try {
        localStorage.setItem('jwtToken', jwtTokenRef.current);
        await HttpContext.updateAccountInfo(jwtTokenRef, userInfoRef);
        console.log('Saved jwtToken');
      } catch (e) {
        console.log(e);
        return;
      }

      console.log(response_json);
      navigate('/transcribe');
      setLoginRegisterPopupVisible(false);
    } else if (response.status < 500) {
      if (typeof response_json.detail === 'string') {
      } else {
      }
    } else {
      console.log(response_json);
    }
  };

  const isVerifiedUser = () => {
    return (
      userInfoRef.current !== undefined && userInfoRef.current.verified_email
    );
  };

  useEffect(() => {
    refreshTokenAndAccountInfo();
    let intervalId;
    if (!(pathname === '/') || !(pathname === '/buy-credits')) {
      // Set up the interval
      intervalId = setInterval(refreshTokenAndAccountInfo, 5000);
    }

    // Clear the interval on component unmount
    return () => intervalId && clearInterval(intervalId);
  }, [pathname]);

  useEffect(() => {
    refreshTokenAndAccountInfo();

    if (pathname == '/verify-email') {
      var email = new URLSearchParams(location.search).get('email');
      var verificationCode = new URLSearchParams(location.search).get(
        'verification_code',
      );
      handleVerifyEmail(email, verificationCode);
    }
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = '';
    let metaDescription = '';

    switch (pathname) {
      case '/':
        title = 'Interpret AI Translator / Transcriber';
        metaDescription = '';
        break;
      case '/filetranslate':
        title = 'Interpret AI File Transcriber';
        metaDescription = '';
        break;
      case '/transcribe':
        title = 'Interpret AI Transcriber';
        metaDescription = '';
        break;
      case '/translate':
        title = 'Interpret AI Transcriber';
        metaDescription = '';
        break;
      case '/zoom_instructions':
        title = 'Interpret AI Transcriber';
        metaDescription = '';
        break;
      case '/docs':
        title = 'Interpret AI Docs';
        metaDescription = '';
        break;
      case '/profile':
        title = 'Interpret AI Profile';
        metaDescription = '';
        break;
      case '/buy-credits':
        title = 'Interpret AI Subscriptions';
        metaDescription = '';
        break;
      case '/login':
        title = '';
        metaDescription = '';
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]',
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <div className='flex flex-col w-full overflow-hidden'>
      {pathname === '/' ||
      pathname === '/plan' ||
      pathname === '/privacy' ||
      pathname === '/tos' ||
      pathname === '/zoom_instructions' ||
      pathname === '/contact' ? (
        <Nav />
      ) : (
        !pathname.includes('broadcast') &&
        !pathname.includes('login') &&
        !pathname.includes('signup') &&
        !pathname.includes('email-verification') &&
        !pathname.includes('forgot-password') &&
        !pathname.includes('verify-email') &&
        !pathname.includes('change-password-forgot') && <ProfileDropdown />
      )}
      <div className='flex w-full overflow-hidden'>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/plan' element={<Plan />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/email-verification' element={<EmailVerification />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/verify-email/*' element={<EmailVerification />} />
          <Route path='/transcribe' element={<Transcribe />} />
          <Route path='/file_transcript' element={<FileTranscript />} />
          <Route path='/file-translate' element={<FileTranslate />} />
          <Route path='/audio-translate' element={<AudioTranslate />} />
          <Route path='/broadcast-host/*' element={<SpeakerRoom />} />
          <Route path='/broadcast/*' element={<BroadcastRoom />} />
          <Route path='/docs' element={<Docs />} />
          <Route path='/zoom_instructions' element={<ZoomInstructions />} />
          <Route path='/profile' element={<ProfileAccountInfo />} />
          <Route
            path='/profile_payment_info'
            element={<ProfileCurrentPlan />}
          />
          <Route path='/buy_plan' element={<ProfileBuyPlan />} />
          <Route path='/change-password-forgot/*' element={<ResetPassword />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/tos' element={<TOS />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </div>

      {(!pathname.includes('broadcast') || showHome) && <LearnMoreContainer />}
    </div>
  );
}
export default AppWeb;
