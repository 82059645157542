import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtTokenRef, serverURL, userInfoRef } from '../../httpContext';
import ProfileDropdown from '../../components/ProfileDropdown';
import RecentsSidebar from './RecentsSidebar';
import { top100WorldLanguages } from '../../languages';
import {
  CloseOutlined,
  LoadingOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { OutOfCreditsWindow } from '../../components/OutOfCreditsWindow';

const AudioTranslate = () => {
  const navigate = useNavigate();

  const [transcript, setTranscript] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [error, setError] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isUploadFileWindowOpen, setUploadFileWindowOpen] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [isDownloadDropdownOpen, setDownloadDropdownOpen] = useState(false);
  const [searchBarInput, setSearchBarInput] = useState('');
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [outputLanguage, setOutputLanguage] = useState('Original');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isOutOfCreditsOpen, setIsOutOfCreditsOpen] = useState(false);
  const [hasZoomIntegration, setHasZoomIntegration] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log('loading zoom oauth callback');
    const code = queryParams.get('code');
    if (code) {
      const redirectUri = window.location.origin + window.location.pathname;
      fetch(`${serverURL}/oauth-callback/zoom`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtTokenRef.current,
        },
        body: JSON.stringify({ code, redirect_uri: redirectUri }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Success:', data);
          setHasZoomIntegration(true);
          // Handle success (e.g., redirect or update state)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, []);

  const transcriptIdRef = useRef(null);
  const fileInputRef = useRef(null);
  const languageDropdownRef = useRef(null);

  const convertRawTranscriptToTranscript = (rawTranscript) => {
    let transcriptMarkdown = '';
    for (let i = 0; i < rawTranscript.transcription.segments.length; i++) {
      transcriptMarkdown +=
        rawTranscript.transcription.segments[i].speaker +
        ': ' +
        rawTranscript.transcription.segments[i].text +
        '\n';
    }
    return transcriptMarkdown;
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      setUploadedFileName('');
      setIsTranscribing(true);
      setUploadFileWindowOpen(false);
      const response = await axios.post(
        serverURL + '/transcribe_file',
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + jwtTokenRef.current,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (event) => {
            const { loaded, total } = event;
            const percentCompleted = Math.round((loaded / total) * 100);
            setUploadProgress(percentCompleted);
          },
        },
      );

      if (response.status !== 200) {
        console.error('Error occurred during file upload.');
        setError('Error occurred during file upload.');
        setIsTranscribing(false);
        return;
      } else {
        const data = response.data;
        console.log(data);
        transcriptIdRef.current = data['transcript_id'];
        console.log('transcript id: ', transcriptIdRef.current);
        navigate('/file_transcript?id=' + transcriptIdRef.current);
        // getTranscript();
        // setError('');
        // return data; // Return the response after upload is complete
      }
    } catch (error) {
      setIsTranscribing(false);
      setUploadedFileName('');
      console.error('Error occurred during file upload.', error);
      setError('Error occurred during file upload.');
    }
  };

  const getTranscript = async (retryCount = 0, maxRetries = 200) => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${serverURL}/get_transcript`, {
          params: {
            transcript_id: transcriptIdRef.current,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtTokenRef.current}`,
          },
        });

        if (response.status === 200 && response.data.transcript != null) {
          setIsTranscribing(false);
          setTranscript(
            convertRawTranscriptToTranscript(
              JSON.parse(response.data.raw_transcript),
            ),
          );
        } else {
          if (retryCount < maxRetries) {
            setTimeout(fetchData, 1000); // Retry after 1 second
          } else {
            setIsTranscribing(false);
            throw new Error('Max retries exceeded.');
          }
        }
      } catch (error) {
        console.error('Error fetching transcript:', error);
        if (retryCount < maxRetries) {
          setTimeout(fetchData, 1000); // Retry after 1 second
        } else {
          setIsTranscribing(false);
          throw new Error('Max retries exceeded.');
        }
      }
    };

    fetchData();
  };

  const changeUploadedFileEventLister = () => {
    if (fileInputRef.current.files.length > 0) {
      setFileSize(
        (fileInputRef.current.files[0].size / (1024 * 1024)).toFixed(2) + ' MB',
      );
      const fileName = fileInputRef.current.files[0].name;
      const shortenedFileName =
        fileName.length > 20
          ? fileName.substring(0, 20) + '...' + fileName.split('.').pop()
          : fileName;
      setUploadedFileName(shortenedFileName);
      setUploadFileWindowOpen(true);
    }
  };

  const addEventListenerToFile = () => {
    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.addEventListener('change', changeUploadedFileEventLister);
    }
    return () => {
      if (fileInput) {
        fileInput.removeEventListener('change', changeUploadedFileEventLister);
      }
    };
  };

  const addBotToMeeting = async (meetingUrl) => {
    if (jwtTokenRef.current == undefined) {
      return;
    }

    const response = await axios.post(
      `${serverURL}/add_bot_to_zoom`,
      { meeting_url: meetingUrl },
      {
        headers: {
          Authorization: `Bearer ${jwtTokenRef.current}`,
        },
      },
    );
    if (response.status === 200) {
      console.log('Bot added to meeting');
      navigate(`/broadcast/${response.data}`);
    } else {
      console.error('Failed to add bot to meeting');
    }
  };

  useEffect(() => {
    addEventListenerToFile();
  }, []);

  // Close the language dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target)
      ) {
        setIsLanguageDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className='relative max-w-[1512px] mx-auto'>
      {isOutOfCreditsOpen && (
        <OutOfCreditsWindow
          onClose={() => {
            setIsOutOfCreditsOpen(false);
          }}
          onBuyCredits={() => {
            setIsOutOfCreditsOpen(false);
            navigate('/buy_plan');
          }}
        ></OutOfCreditsWindow>
      )}
      {isUploadFileWindowOpen && (
        <div className='fixed inset-0 rounded-xl bg-white shadow-lg flex flex-col items-center justify-center p-[50px] gap-[40px] w-fit m-auto h-fit z-20 font-montserrat'>
          <div className='flex flex-col items-center justify-start gap-[64px]'>
            <div className='flex flex-col items-start justify-start gap-[20px]'>
              <div className='flex flex-col items-start justify-start'>
                <div className='relative leading-[22px] text-lg'>
                  Audio/Video
                </div>
              </div>
              <div className='[background:linear-gradient(102.81deg,_rgba(0,_126,_243,_0.1),_rgba(121,_119,_209,_0.1)),_#fff] overflow-hidden flex flex-col items-center justify-start pt-2.5 px-0 pb-[102px] gap-[64px] text-base'>
                <div className='w-[621px] flex flex-row items-start justify-end py-2.5 pr-2.5 pl-[242px] box-border'>
                  <CloseOutlined
                    onClick={() => setUploadFileWindowOpen(false)}
                  />
                </div>
                <div className='w-[621px] flex flex-col items-center justify-start py-0 px-[72px] box-border gap-[5px]'>
                  <div className='relative leading-[22px] font-medium'>
                    {uploadedFileName}
                  </div>
                  <div className='relative text-sm leading-[22px] text-updated-color-grey2'>
                    mov・{fileSize}
                  </div>
                </div>
              </div>
            </div>
            <div className='flex self-end gap-[20px] text-base'>
              <div
                className='rounded-3xs flex flex-row items-center justify-center py-[5px] px-[15px] border-[0.5px] border-solid border-updated-color-grey2 cursor-pointer'
                onClick={() => {
                  setUploadFileWindowOpen(false);
                  setUploadedFileName('');
                  addEventListenerToFile();
                  setTimeout(() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.value = '';
                    }
                  }, 0);
                }}
              >
                <div className='relative leading-[22px] font-medium'>
                  Cancel
                </div>
              </div>
              <div
                className='rounded-3xs [background:linear-gradient(rgba(0,_126,_243,_0.3),_rgba(0,_126,_243,_0.3)),_#fff] flex flex-row items-center justify-center py-[5px] px-[15px] border-[0.5px] border-solid border-updated-color-grey1 cursor-pointer'
                onClick={() => {
                  if (userInfoRef.current?.credits <= 10) {
                    setIsOutOfCreditsOpen(true);
                    return;
                  }
                  if (fileInputRef.current.files.length === 0) {
                    alert('Please select a file to transcribe.');
                    return;
                  }
                  uploadFile(fileInputRef.current.files[0]);
                }}
              >
                <div className='relative leading-[22px] font-medium'>
                  Transcribe
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='bg-white text-left text-xl text-updated-color-new-black font-montserrat flex flex-col justify-center'>
        {isTranscribing && (
          <div className='flex flex-col items-center justify-center gap-5 text-center text-[28px] py-36'>
            <LoadingOutlined
              className='text-[#007EF3] mb-6'
              style={{ fontSize: '60px' }}
            />
            <b className='relative leading-[22px]'>Transcribing...</b>
            <div className='relative text-lg leading-[22px] font-semibold'>
              Extracting the audio
            </div>
            <div className='relative text-lg leading-[22px] font-semibold'>
              Upload Progress: {uploadProgress}%
            </div>
            <div className='relative text-sm leading-[22px] font-semibold'>
              Please do not leave this page while it's uploading.
            </div>
          </div>
        )}
        {!isTranscribing && !transcript && (
          <div className='flex gap-20 justify-center min-h-screen px-20 py-10'>
            <div className='gap-10 [filter:drop-shadow(0px_3px_5.5px_rgba(140,_140,_140,_0.25))] rounded-xl flex flex-col items-center justify-center h-fit py-20 px-40 text-[24px] border-[1px] border-solid border-updated-color-grey1'>
              <div className='flex flex-col items-center justify-start gap-[25px]'>
                <img
                  className='w-fit h-fit'
                  alt=''
                  src='/files--folder-music.svg'
                />
                <div className='flex flex-col items-center justify-start gap-[10px]'>
                  <div className='relative leading-[22px] font-semibold'>
                    Transcribe Audio / Video
                  </div>
                  <div className='w-[420px] relative text-base leading-[22px] text-center inline-block'>
                    Please drag and drop an audio file or mp4 video here to have
                    it transcribed into your chosen language.
                  </div>
                </div>
              </div>
              <div className='flex flex-row items-start justify-start text-center text-sm cursor-pointer relative'>
                <input
                  type='file'
                  accept='audio/*, video/mp4'
                  ref={fileInputRef}
                  className='absolute opacity-0 w-full h-full z-10 cursor-pointer'
                />
                <div className='flex flex-row items-center justify-center'>
                  <div className='rounded-tl-23xl rounded-tr-none rounded-br-none rounded-bl-23xl bg-white flex flex-row items-center justify-center py-0.5 px-[15px] border-[0.5px] border-solid border-updated-color-grey1'>
                    <div className='relative leading-[22px]'>
                      {uploadedFileName ? uploadedFileName : 'Select a file'}
                    </div>
                  </div>
                  <div className='rounded-tl-none rounded-tr-22xl rounded-br-22xl rounded-bl-none bg-white flex flex-row items-center justify-center py-1 px-[5px] border-[0.5px] border-solid border-updated-color-grey1'>
                    <img
                      className='w-[18px] relative h-[18px] overflow-hidden shrink-0'
                      alt=''
                      src='/arrow--arrow-down-2.svg'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              <div className='flex items-center w-[300px] rounded-full'>
                <img src='/cam.svg' alt='' className='w-6 h-6 -mr-7' />
                <input
                  placeholder='Paste meeting URL to get caption'
                  className='text-base px-4 pl-8 py-2 w-full border-solid rounded-full border-[.5px] focus:outline-none bg-transparent'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addBotToMeeting(e.target.value);
                    }
                  }}
                />
              </div>
              <button
                className='w-[250px] bg-blue-600 hover:bg-blue-700 text-white rounded-full py-2 px-6 flex items-center gap-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-105'
                onClick={() => {
                  if (!hasZoomIntegration) {
                    window.location.href =
                      'https://zoom.us/oauth/authorize?response_type=code&client_id=Ra9qH0RyS_2WdWqQnaFWMw&redirect_uri=https://interpretapp.ai/transcribe';
                  } else {
                    window.location.href =
                      'https://marketplace.zoom.us/user/dashboard'; // Link to disable integration
                  }
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20' // Reduced width for better alignment
                  height='20' // Reduced height
                  viewBox='0 0 50 50'
                  fill='white'
                  stroke='white'
                  strokeWidth='2'
                >
                  <path d='M 16.380859 4 C 9.5545055 4 4 9.5545055 4 16.380859 L 4 33.619141 C 4 40.445495 9.5545055 46 16.380859 46 L 33.619141 46 C 40.445495 46 46 40.445495 46 33.619141 L 46 16.380859 C 46 9.5545055 40.445495 4 33.619141 4 L 16.380859 4 z M 16.380859 6 L 33.619141 6 C 39.364786 6 44 10.635214 44 16.380859 L 44 33.619141 C 44 39.364786 39.364786 44 33.619141 44 L 16.380859 44 C 10.635214 44 6 39.364786 6 33.619141 L 6 16.380859 C 6 10.635214 10.635214 6 16.380859 6 z M 37.203125 16.628906 C 36.897671 16.625906 36.588652 16.704536 36.306641 16.880859 L 30.673828 20.400391 C 29.994839 18.439614 28.178052 17 25.994141 17 L 13 17 A 1.0001 1.0001 0 0 0 12 18 L 12 27.994141 C 12 30.746526 14.253474 33 17.005859 33 L 30 33 A 1.0001 1.0001 0 0 0 31 32 L 31 28.802734 L 36.306641 32.119141 C 37.434686 32.824447 39 31.957395 39 30.626953 L 39 18.373047 C 39 17.375216 38.119486 16.63778 37.203125 16.628906 z M 37 18.804688 L 37 30.195312 L 31 26.445312 L 31 22.554688 L 37 18.804688 z M 14 19 L 25.994141 19 C 27.665755 19 29 20.334245 29 22.005859 L 29 27 L 29 31 L 17.005859 31 C 15.334245 31 14 29.665755 14 27.994141 L 14 19 z'></path>
                </svg>
                <span className='text-base'>Integrate with Zoom</span>
              </button>
              <RecentsSidebar></RecentsSidebar>
            </div>
          </div>
        )}
        {!isTranscribing && transcript && (
          <div className='px-36 py-10'>
            <div className='flex flex-col gap-[30px]'>
              <div className='flex items-center gap-[20px] relative justify-between'>
                <div
                  className='relative rounded-full flex flex-row items-center justify-center p-2.5 px-8 border-solid border-[#007EF3] cursor-pointer'
                  onClick={() =>
                    setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
                  }
                >
                  <div className='relative leading-[112.4%] font-medium'>
                    {outputLanguage}
                  </div>
                  <img
                    className='w-[18px] relative h-[18px] overflow-hidden shrink-0 ml-2'
                    alt=''
                    src='/arrow--arrow-down-2.svg'
                  />
                </div>
                {isLanguageDropdownOpen && (
                  <div
                    ref={languageDropdownRef}
                    className='absolute grid grid-cols-4 top-12 z-10 bg-white border-solid border-gray-300 rounded-md shadow-lg max-h-96 overflow-y-auto w-full px-4'
                  >
                    <div className='col-span-4 flex items-center p-2 border-b border-solid border-x-0 border-t-0 border-gray-300'>
                      <input
                        type='text'
                        placeholder='Search...'
                        className='w-full p-1 border-none outline-none text-lg'
                        value={searchBarInput}
                        onChange={(e) => setSearchBarInput(e.target.value)}
                      />
                      <img
                        className='w-5 h-5 ml-2'
                        alt=''
                        src='/general--search.svg'
                      />
                    </div>
                    <div
                      key={'Original'}
                      className='p-2 hover:bg-gray-200 cursor-pointer'
                      onClick={() => {
                        setOutputLanguage('Original');
                        setIsLanguageDropdownOpen(false);
                      }}
                    >
                      {'Original'}
                    </div>
                    {top100WorldLanguages
                      .filter((language) =>
                        language
                          .toLowerCase()
                          .includes(searchBarInput?.toLowerCase() || ''),
                      )
                      .map((language) => (
                        <div
                          key={language}
                          className='p-2 hover:bg-gray-200 cursor-pointer h-fit w-full'
                          onClick={() => {
                            setOutputLanguage(language);
                            setIsLanguageDropdownOpen(false);
                          }}
                        >
                          {language}
                        </div>
                      ))}
                  </div>
                )}
                <div
                  className='rounded-8xs bg-updated-color-blue flex items-center gap-2 justify-center p-2.5 text-white cursor-pointer z-20 relative'
                  onClick={() => {
                    setDownloadDropdownOpen(!isDownloadDropdownOpen);
                  }}
                >
                  <div className='relative leading-[112.4%] font-medium'>
                    Download
                  </div>
                  <DownOutlined className='' />
                </div>
                {isDownloadDropdownOpen && (
                  <div className='absolute z-10 top-14 right-0 w-fit flex flex-col gap-4 rounded-8xs bg-white shadow-lg p-2'>
                    <div
                      className='rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer'
                      onClick={() => {
                        const element = document.createElement('a');
                        const file = new Blob([transcript], {
                          type: 'text/plain',
                        });
                        element.href = URL.createObjectURL(file);
                        element.download = 'transcript.txt';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                      }}
                    >
                      <div className='relative leading-[112.4%] font-medium'>
                        Download as TXT
                      </div>
                    </div>
                    <div
                      className='rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer'
                      onClick={() => {
                        const element = document.createElement('a');
                        const file = new Blob([transcript], {
                          type: 'application/pdf',
                        });
                        element.href = URL.createObjectURL(file);
                        element.download = 'transcript.pdf';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                      }}
                    >
                      <div className='relative leading-[112.4%] font-medium'>
                        Download as PDF
                      </div>
                    </div>
                    <div
                      className='rounded-3xs border-updated-color-grey1 border-[0.5px] border-solid flex flex-row items-center justify-center py-[5px] px-[15px] cursor-pointer'
                      onClick={() => {
                        const element = document.createElement('a');
                        const file = new Blob([transcript], {
                          type: 'application/x-subrip',
                        });
                        element.href = URL.createObjectURL(file);
                        element.download = 'transcript.srt';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                      }}
                    >
                      <div className='relative leading-[112.4%] font-medium'>
                        Download as SRT
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='shadow-[1px_1px_2.8px_rgba(140,_140,_140,_0.16)_inset] rounded-xl bg-updated-color-translate-box-bkg box-border h-96 max-h-96 overflow-y-auto flex flex-col items-center justify-start py-[30px] px-[50px] text-sm border-[0.5px] border-solid border-updated-color-grey1'>
                <div className='w-full relative leading-[25px] font-medium'>
                  <p className='m-0'>{transcript}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioTranslate;
