// SocketContext.js
import { createContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { jwtTokenRef } from './httpContext';

// export const socket = io('http://127.0.0.1:5000', {
//   path: '/ws/socket.io/',
//   transports: ['websocket', 'polling'],
//   reconnection: true,
//   reconnectionAttempts: Infinity,
// }); // Connect to your server. Change the URL to match your server's.

// export const socket = io('http://192.168.0.113:5000'); // Connect to your server. Change the URL to match your server's.

// export const socket = io('http://10.0.0.73:5000'); // Connect to your server. Change the URL to match your server's.

export const socket = io('https://interpretapp.ai:5001', {
  path: '/ws/socket.io/',
  transports: ['websocket'],
  reconnection: true,
  reconnectionAttempts: Infinity,
}); // Connect to your server. Change the URL to match your server's.

socket.on('connect', () => {
  console.log('Connected to server!');
});

socket.on('disconnect', () => {
  console.log('Disconnected from server!');
});

export const sendMessage = (message) => {
  console.log('Sending message:', message);
  socket.emit('message', message); // Send a message to the server
};
